import { createContext, FunctionalComponent, h } from 'preact';
import { useContext, useState } from 'preact/hooks';
import { IAuthService, IMessageService, IServiceContext, IUserService, IVerifyService } from './types';

const ServiceContext = createContext<IServiceContext>({} as any);

interface IProps {
    authService: IAuthService;
    messenger: IMessageService;
    verifyService: IVerifyService;
    userService: IUserService;
}

export const ServiceProvider: FunctionalComponent<IProps> = (props) => {
    const [serviceState] = useState<IServiceContext>({
        authService: props.authService,
        messageService: props.messenger,
        verifyService: props.verifyService,
        userService: props.userService,
    });

    return (
        <ServiceContext.Provider value={serviceState}>
            {props.children}
        </ServiceContext.Provider>
    );
};

export function useServices() {
    const context = useContext(ServiceContext);
    if (!context) {
        throw new Error();
    }

    return context;
}