import { CognitoUser } from 'amazon-cognito-identity-js';
import { IAuthService, IMessageService, IMultiFactorAuthChallenge, IUserAuthenticationInfo, IUserInfo, IUserService, IVerifyService, PushChallengeStatus } from '../../services';
import { AuthActionType, IAuthStateAction } from '../types';
import { AuthenticationType } from '../../utils/Browser';
import { route } from 'preact-router';
import { ChallengeErrorType, IChallengeError, IMultiFactorAuthActions, IRestrictedSession } from './contracts';

export class MultiFactorAuthActions implements IMultiFactorAuthActions {
    constructor(
        private readonly authService: IAuthService,
        private readonly messageService: IMessageService,
        private readonly verifyService: IVerifyService,
        private readonly userService: IUserService,
        private readonly dispatch: (a: IAuthStateAction) => void,) {
    }

    public async onRestrictedSessionCreated(restrictedUser: CognitoUser) {
        const restrictedToken = restrictedUser.getSignInUserSession()?.getAccessToken();
        if (!restrictedToken) {
            throw new Error();
        }

        const userAuthInfo = await this.verifyService.getUserAuthenticationInfo(restrictedToken);

        const restrictedSession: IRestrictedSession = {
            user: restrictedUser,
            userAuthInfo,
        };

        this.dispatch({
            type: AuthActionType.RESTRICTED_SESSION_CREATED,
            data: restrictedSession,
        });

        const initialAuthType = getInitialAuthenticationType(userAuthInfo);
        await this.initMultiFactorAuth(restrictedSession, initialAuthType);
    }

    public async initMultiFactorAuth(restrictedSession: IRestrictedSession, authType: AuthenticationType): Promise<void> {
        this.dispatch({
            type: AuthActionType.MFA_CHALLENGE_INIT,
            data: authType,
        });

        route('/multi-factor');

        try {
            const restrictedToken = restrictedSession.user.getSignInUserSession()?.getAccessToken();

            const initialChallenge = await this.authService.initiateAuth(restrictedSession.user, authType);
            if (initialChallenge.session) {
                throw Error();
            }

            if (initialChallenge.challenge.data.type !== 'PAYHAWK_RESTRICTED_TOKEN') {
                throw Error();
            }

            const result = await this.authService.resolveAuthChallenge(initialChallenge.challenge, restrictedToken!.getJwtToken());

            if (result.session) {
                this.messageService.userSignedIn(result.session);
            } else {
                this.dispatch({
                    type: AuthActionType.MFA_CHALLENGE_INIT_SUCCESS,
                    data: result.challenge,
                });
            }
        } catch (e: any) {
            const challengeError: IChallengeError = {
                type: ChallengeErrorType.Unknown,
                error: e.message || e,
            };

            this.dispatch({
                type: AuthActionType.MFA_CHALLENGE_INIT_FAIL,
                data: challengeError,
            });
        }
    }

    public userSignedIn(userSession: IUserSession): void {
        this.messageService.userSignedIn(userSession);
    }

    public async resolveSmsChallenge(challenge: IMultiFactorAuthChallenge, restrictedSession: IRestrictedSession, code: string) {
        const restrictedToken = restrictedSession.user.getSignInUserSession()?.getAccessToken();
        if (!restrictedToken) {
            throw new Error();
        }

        this.dispatch({
            type: AuthActionType.MFA_CHALLENGE_RESOLVE
        });

        const resolveStatus = await this.verifyService.resolveSmsChallenge(restrictedToken, challenge.data.challengeId, code);

        switch (resolveStatus) {
            case 'approved':
                await this.resolveChallenge(challenge, restrictedSession);
                break;
            case 'canceled':
            case 'pending':
                const challengeError: IChallengeError = {
                    type: resolveStatus === 'pending' ? ChallengeErrorType.InvalidCode : ChallengeErrorType.Expired,
                };

                this.dispatch({
                    type: AuthActionType.MFA_CHALLENGE_RESOLVE_FAIL,
                    data: challengeError,
                });

                break;
        }
    }

    public async resolveChallenge(challenge: IMultiFactorAuthChallenge, restrictedSession: IRestrictedSession) {
        this.dispatch({
            type: AuthActionType.MFA_CHALLENGE_RESOLVE
        });

        try {
            const result = await this.authService.resolveAuthChallenge(challenge);

            if (result.session) {
                const canSetupPushAuth = restrictedSession.userAuthInfo.allowedFactors.pushAuthentication && !restrictedSession.userAuthInfo.configuredFactors.pushAuthentication;

                if (canSetupPushAuth) {
                    route('/multi-factor/push-auth-setup', true);
                    this.dispatch({
                        type: AuthActionType.FULL_SESSION_CREATED,
                        data: result.session,
                    });
                } else {
                    this.messageService.userSignedIn(result.session);
                }
            } else {
                this.dispatch({
                    type: AuthActionType.MFA_CHALLENGE_RESOLVE_SUCCESS,
                    data: result.challenge,
                });
            }
        } catch (e: any) {
            this.dispatch({
                type: AuthActionType.MFA_CHALLENGE_RESOLVE_FAIL,
                data: e.message || e,
            });
        }
    }

    public declinePushChallenge() {
        route('/multi-factor/push-auth-declined', true);
        this.dispatch({
            type: AuthActionType.CLEAN_SIGN_IN,
        });
    }

    public async getPushChallengeStatus(challenge: IMultiFactorAuthChallenge, restrictedSession: IRestrictedSession): Promise<PushChallengeStatus> {
        const restrictedToken = restrictedSession.user.getSignInUserSession()?.getAccessToken();
        if (!restrictedToken) {
            throw new Error();
        }

        const { challengeId } = challenge.data;

        return await this.verifyService.getPushAuthChallengeStatus(restrictedToken, challengeId);
    }

    public async updateUserAuthInfo(restrictedSession: IRestrictedSession): Promise<void> {
        const restrictedToken = restrictedSession.user.getSignInUserSession()?.getAccessToken();
        if (!restrictedToken) {
            throw new Error();
        }

        const userAuthInfo = await this.verifyService.getUserAuthenticationInfo(restrictedToken);

        this.dispatch({
            type: AuthActionType.RESTRICTED_SESSION_UPDATE,
            data: { ...restrictedSession, userAuthInfo },
        });
    }

    async getUserInfo(restrictedUser: CognitoUser): Promise<IUserInfo | undefined> {
        const restrictedToken = restrictedUser.getSignInUserSession()?.getAccessToken();
        if (!restrictedToken) {
            throw new Error();
        }

        const userInfo = await this.userService.getUserData(restrictedToken);

        return userInfo;
    }

    async updateUserInfo(restrictedUser: CognitoUser, user: IUserInfo, patch: Pick<IUserInfo, 'familyName' | 'givenName' | 'phoneNumber'>): Promise<IUserInfo> {
        const restrictedToken = restrictedUser.getSignInUserSession()?.getAccessToken();
        if (!restrictedToken) {
            throw new Error();
        }

        await this.userService.updateUserData(restrictedToken, user.sub, patch);

        const updatedUserInfo = { ...user, ...patch };

        if (updatedUserInfo.phoneNumberVerified && user.phoneNumber !== patch.phoneNumber) {
            updatedUserInfo.phoneNumberVerified = false;
        }

        return updatedUserInfo;
    }

    async initPhoneVerification(restrictedUser: CognitoUser): Promise<void> {
        await this.authService.initPhoneVerification(restrictedUser);
    }

    async verifyPhone(restrictedUser: CognitoUser, code: string): Promise<void> {
        const restrictedToken = restrictedUser.getSignInUserSession()?.getAccessToken();
        if (!restrictedToken) {
            throw new Error();
        }

        await this.userService.verifyPhone(restrictedToken, code);
    }
}

function getInitialAuthenticationType(userAuthSettings: IUserAuthenticationInfo): AuthenticationType {
    if (userAuthSettings.mfaRequired) {
        if (userAuthSettings.allowedFactors.pushAuthentication
            && (userAuthSettings.configuredFactors.pushAuthentication || !userAuthSettings.allowedFactors.sms)) {
            return AuthenticationType.PushAuth;
        }

        if (userAuthSettings.allowedFactors.sms) {
            return AuthenticationType.Sms;
        }
    }

    return AuthenticationType.SingleFactor;
}