import { ITextInputProps, TextInput } from '@payhawk/hawky-react';
import { h } from 'preact';
import { useAutoFocus } from './useAutoFocus';

export const TextInputAutoFocus = (props: ITextInputProps): JSX.Element => {
    const inputRef = useAutoFocus();

    return (
        <TextInput
            innerRef={inputRef}
            {...props}
        />
    );
};
