import { h } from 'preact';
import { useCallback, useState } from 'preact/hooks';
import { Trans, useTranslation } from 'react-i18next';
import { Button, classNames, CodeField } from '@payhawk/hawky-react';
import { useAuth } from '../../auth';
import styles from './SecurityCode.module.scss';

interface IProps {
    title: string;
    buttonText: string;
    errorMessageKey?: string;
    errorAction?: () => void;
    subtitle?: JSX.Element;
    footerText?: string;
    onConfirm(code: string): void;
    onChange?(code: string): void;
    onFooterClick?(): void;
}

const SecurityCode: preact.FunctionalComponent<IProps> = ({
    title,
    subtitle,
    buttonText,
    errorMessageKey,
    errorAction,
    footerText,
    onConfirm,
    onChange,
    onFooterClick,
}: IProps) => {
    const { t } = useTranslation(['common']);
    const [_authState, authActions] = useAuth();
    const [code, setCode] = useState('');
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

    const onCancel = useCallback(() => {
        authActions.cancelSecurityCodeChange();
    }, [authActions]);

    const onSubmit = useCallback(() => {
        onConfirm(code);
        setIsSubmitDisabled(true);
        setCode('');
    }, [code, setCode, setIsSubmitDisabled, onConfirm]);

    const onComplete = useCallback((input: string) => {
        setCode(input);
        setIsSubmitDisabled(false);
    }, [setCode, setIsSubmitDisabled]);

    return (
        <form class={styles.wrap}>
            <div className={styles.title}>
                {title}
                {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
            </div>
            <div className={styles.codeWrapper}>
                <CodeField
                    digitCount={4}
                    onChange={onChange}
                    onComplete={onComplete}
                    className={styles.code}
                    isMasked
                    initialAutoFocus
                />
                 <div class={styles.error}>
                    {errorMessageKey &&
                        <Trans ns='common' i18nKey={errorMessageKey as any}>
                            Incorrect code. Try again or <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={errorAction}>start over</span>.
                        </Trans>
                    }
                </div>
            </div>
            <div className={classNames('g-2-16', styles.buttonsWrapper)}>
                <Button
                    isExpanded
                    skin='primary'
                    isDisabled={isSubmitDisabled}
                    label={buttonText}
                    onClick={onSubmit}
                />
                <Button
                    isExpanded
                    skin='secondary'
                    label={t('common:cancel')}
                    onClick={onCancel}
                />
            </div>
            {footerText && onFooterClick &&
                <div class={styles.linkWrapper} onClick={onFooterClick}>
                    <span class={styles.link}>{footerText}</span>
                </div>
            }
        </form>
    );
};

export default SecurityCode;
