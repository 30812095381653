import { h } from 'preact';

import { Alert, Button, CodeField, ContentHeader, Text } from '@payhawk/hawky-react';
import { TFunction, Trans, useTranslation } from 'react-i18next';
import { useAuth } from '../../auth';
import { ISmsCognitoChallenge } from '../../services';
import { useState } from 'react';
import { AuthenticationType } from '../../utils/Browser';

import styles from './MultiFactorAuth.module.scss';
import { ChallengeErrorType } from '../../auth/multi-factor-auth';

const CODE_LENGTH = 6;

export const SmsVerify = () => {
    const [ authState, _, multiFactorAuthActions ] = useAuth();
    const { isInitiating, isResolving, restrictedSession, challenge, challengeError } = authState.multiFactorAuth;
    const [ code, setCode ] = useState('');
    const [ isCodeResent, setIsCodeResent ] = useState(false);
    const { t } = useTranslation(['auth', 'common']);

    const hasSmsChallenge = !!(restrictedSession && challenge?.data.type === 'PAYHAWK_SMS');

    const onSendHandler = async () => {
        setIsCodeResent(false);

        if (!hasSmsChallenge || isResolving) {
            return;
        }

        await multiFactorAuthActions.resolveSmsChallenge(challenge, restrictedSession, code);
    };

    const onResendHandler = async () => {
        setIsCodeResent(false);

        if (!hasSmsChallenge || isResolving) {
            return;
        }

        await multiFactorAuthActions.initMultiFactorAuth(restrictedSession, AuthenticationType.Sms);
        setIsCodeResent(true);
    };

    const onUsePushAuthHandler = async () => {
        setIsCodeResent(false);

        if (isInitiating || isResolving || !restrictedSession) {
            return;
        }

        await multiFactorAuthActions.initMultiFactorAuth(restrictedSession, AuthenticationType.PushAuth);
    };

    if (!hasSmsChallenge) {
        return null;
    } 

    const smsChallengeData = challenge.data as ISmsCognitoChallenge | undefined;
    const phoneNumber = smsChallengeData?.phone;
    const isVerifyDisabled = isInitiating ||
        isResolving ||
        code.length !== CODE_LENGTH ||
        challengeError?.type === ChallengeErrorType.Expired ||
        challengeError?.type === ChallengeErrorType.Unknown;
    const canUsePushAuthFactor: boolean = !!(restrictedSession?.userAuthInfo?.allowedFactors.pushAuthentication && restrictedSession.userAuthInfo.configuredFactors.pushAuthentication);

    return (
        <div className={styles.container}>
            <ContentHeader
                type='large-centered'
                title={t('auth:mfa.sms.title')}
                startSubtitle={(
                    <span>
                        <Trans
                            ns='auth'
                            i18nKey='mfa.sms.description'
                            values={{ phoneNumber }}
                            components={{ b: <b /> }}
                        />
                    </span>
                )}
            />

            {isCodeResent && <Alert
                type='info'
                startTitle={t('auth:mfa.sms.resendCodeConfirmationTitle')}
                description={t('auth:mfa.sms.resendCodeConfirmationDescription')}
                className='mt-40'
            />}

            <div className={`d-flex flex-column align-items-center ${isCodeResent ? 'mt-24' : 'my-40'}`}>
                <Text.BodyLarge> {t('auth:mfa.sms.enterCode')} </Text.BodyLarge>

                <CodeField
                    digitCount={CODE_LENGTH}
                    onComplete={setCode}
                    type='number'
                    initialAutoFocus
                    className={challengeError ?  'my-8' : 'mt-8 mb-24'}
                />

                <Text color='danger-5' size='12' align='center' weight='600'>{getErrorMessage(challengeError?.type, t)}</Text>
            </div>

            <Button
                label={t('auth:mfa.sms.verifyButton')}
                isExpanded
                className='my-24'
                onClick={onSendHandler}
                isLoading={isResolving}
                isDisabled={isVerifyDisabled}
            />
            
            <div className={`d-flex justify-content-center`}>
                {canUsePushAuthFactor && <Button
                    label={t('auth:mfa.pushAuth.usePushAuth')}
                    skin='link'
                    size='small'
                    onClick={onUsePushAuthHandler}
                    isDisabled={isInitiating}
                    className='mr-12'
                />}
                <Button
                    label={t('auth:mfa.sms.resendCode')}
                    skin='link'
                    size='small'
                    onClick={onResendHandler}
                    isLoading={isInitiating}
                    isDisabled={isInitiating}
                />
            </div>
        </div>
    );
};

SmsVerify.displayName = 'SmsVerify';

function getErrorMessage(e: ChallengeErrorType | undefined, t: TFunction<('auth' | 'common')[]>): string {
    switch (e) {
        case ChallengeErrorType.Expired:
            return t('auth:mfa.sms.expiredCode');
        case ChallengeErrorType.InvalidCode:
            return t('auth:mfa.sms.incorrectCode');
        case ChallengeErrorType.Unknown:
            return t('common:unknownError');
        default:
            return '';
    }
}