import { createContext, FunctionalComponent, h } from 'preact';
import { useContext, useReducer, useState } from 'preact/hooks';
import { useTranslation } from 'react-i18next';

import { useServices } from '../services';
import { AuthActions } from './AuthActions';
import authReducer from './AuthReducer';
import { IAuthActions, IAuthState, IAuthStateAction } from './types';
import * as MultiFactorAuth from './multi-factor-auth';

const defaultState: IAuthState = {
    email: '',
    isSigningIn: false,
    isSigningInWithExternalProvider: false,
    isCheckingEmail: false,
    isSigningUp: false,
    isResettingPassword: false,
    isGettingSecurityCode: true,
    isVerifyingSecurityCode: false,
    isResendingVerificationCode: false,
    isGettingCardDetails: false,
    isSendingNewSecurityCode: false,
    isSendingResetSecurityCode: false,
    isCodeReset: false,
    isShowPasswordCheck: false,
    passwordCheckLoading: false,
    securityCode: '',
    multiFactorAuth: {
        isInitiating: false,
        isResolving: false,
    }
};

const AuthStateContext = createContext<IAuthState>(defaultState);
const AuthActionsContext = createContext<IAuthActions>({
    setEmail: () => { },
    signIn: async () => { },
    externalProviderSignIn: () => { },
    processAuthenticationParams: async () => {},
    checkEmail: async () => { },
    resetPassword: async () => { },
    resetPasswordConfirm: async () => { },
    signUp: async () => { },
    verifySignUp: async () => { },
    confirmSignUp: async () => { },
    cleanSignUp: async () => { },
    verifyInvitation: async () => { },
    resendVerificationCode: async () => { },
    contactSupport: () => { },
    hasSecurityCode: async () => { },
    setSecurityCode: async () => { },
    verifySecurityCode: async () => { },
    getCardDetails: async () => { },
    cardDetailsOpened: () => { },
    cancelSecurityCodeChange: () => { },
    securityCodeChangedSuccessfully: () => { },
    resetSecurityCode: () => { },
    checkPassword: async () => false,
    showPasswordCheck: async () => { },
    changeHeight: () => { },
    changeTitleToSecurityCodeDisabled: async () => { },
});

const AuthActionsContextV3 = createContext<MultiFactorAuth.IMultiFactorAuthActions>({} as any);

function combinedReducer(state: IAuthState, action: IAuthStateAction): IAuthState {
    return {
        ...authReducer(state, action),
        multiFactorAuth: MultiFactorAuth.multiFactorAuthReducer(state.multiFactorAuth, action),
    };
}

export const AuthContextProvider: FunctionalComponent = (props) => {
    const { t } = useTranslation(['common']);
    const { authService, messageService, verifyService, userService } = useServices();
    const [state, dispatch] = useReducer<IAuthState, IAuthStateAction>(combinedReducer, defaultState);
    const [multiFactorAuthActions] = useState<MultiFactorAuth.IMultiFactorAuthActions>(() => {
        return new MultiFactorAuth.MultiFactorAuthActions(authService, messageService, verifyService, userService, dispatch);
    });
    const [authActions] = useState<IAuthActions>(() => {
        return new AuthActions(multiFactorAuthActions, authService, messageService, dispatch, t);
    });

    return (
        <AuthStateContext.Provider value={state}>
            <AuthActionsContext.Provider value={authActions}>
                <AuthActionsContextV3.Provider value={multiFactorAuthActions}>
                    {props.children}
                </AuthActionsContextV3.Provider>
            </AuthActionsContext.Provider>
        </AuthStateContext.Provider>
    );
};

export function useAuth(): [IAuthState, IAuthActions, MultiFactorAuth.IMultiFactorAuthActions] {
    const stateContext = useContext(AuthStateContext);
    if (!stateContext) {
        throw new Error('useAuth must be inside an AuthContextProvider');
    }

    const actionsContext = useContext(AuthActionsContext);
    if (!actionsContext) {
        throw new Error('useAuth must be inside an AuthContextProvider');
    }

    const multiFactorActionsContext = useContext(AuthActionsContextV3);
    if (!multiFactorActionsContext) {
        throw new Error('useAuth must be inside an AuthContextProvider');
    }

    return [stateContext, actionsContext, multiFactorActionsContext];
}
