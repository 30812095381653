import { h } from 'preact';

import { useTranslation } from 'react-i18next';
import { PushAuthSetup } from './PushAuthSetup';
import { Button } from '@payhawk/hawky-react';
import { useAuth } from '../../auth';

export const PushAuthSetupOptional = () => {
    const { t } = useTranslation(['common']);

    const [ authState, _, multiFactorAuthActions ] = useAuth();
    const { userSession } = authState.multiFactorAuth;

    if (!userSession) {
        return null; // navigate away?
    }

    const skipHandler = () => {
        multiFactorAuthActions.userSignedIn(userSession);
    };

    return (
        <PushAuthSetup>
            <Button label={t('common:skipForLater')} onClick={skipHandler} skin='link' />
        </PushAuthSetup>
    );
};

PushAuthSetupOptional.displayName = 'PushAuthSetupOptional';
