import countries from './countries.json';
import { SUPPORTED_COUNTRIES } from './supported-countries';

export interface ICountry {
    name: string;
    alpha2Code: string;
    alpha3Code: string;
    callingCodes: string[];
}

export const Countries = countries as ICountry[];

export const CountriesSelectOptions = Countries
    .map(x => ({ id: x.alpha2Code, label: x.name }));

export function getCountry(alpha2Code?: string) {
    return Countries.find(c => c.alpha2Code === alpha2Code);
}

export function getCountryMap() {
    const countriesMap: { [key: string]: string } = {};

    Countries.forEach(country => countriesMap[country.alpha3Code] = country.name);

    return countriesMap;
}

export const getCountryCodeByCountryName = (countryName: string): string => {
    if (!countryName) {
        return '';
    }

    const locatedCountry = SUPPORTED_COUNTRIES.find(({ fullName }) => fullName === countryName.trim());

    return locatedCountry?.code || '';
};
