import { Button, ContentHeader } from '@payhawk/hawky-react';
import { h } from 'preact';

import styles from './MultiFactorAuth.module.scss';
import { useTranslation } from 'react-i18next';
import { route } from 'preact-router';

export const DeclinedPushAuth = () => {
    const { t } = useTranslation(['auth', 'common']);

    const tryAgainHandler = () => {
        route('/', true);
    };

    return (
        <div className={styles.container}>
            <div className='d-flex align-items-center flex-column'>
                <ContentHeader
                    type='large-centered'
                    title={t('auth:mfa.pushAuth.declined')}
                    startSubtitle={t('auth:mfa.pushAuth.declinedDescription')}
                    className='mb-32 mt-32'
                />

                <div>
                    <Button label={t('common:tryAgain')} onClick={tryAgainHandler} skin='tertiary' />
                </div>
            </div>
        </div>
    );
};

DeclinedPushAuth.displayName = 'DeclinedPushAuth';
