import { h } from 'preact';
import { useTranslation } from 'react-i18next';
import { getSearch } from '../../utils/Browser';

import styles from './ResetPassword.module.scss';
import {
    Alert,
    ContentHeader,
} from '@payhawk/hawky-react';

const ResetPasswordConfirm: preact.FunctionalComponent = () => {
    const { t } = useTranslation(['common']);

    const AlertDescription = () => {
        return (
            <div className='mr-4'>
                {t('common:invitationLinkResent')}
            </div>
        );
    };

    return (
        <div class={styles.container}>
            <ContentHeader
                type='large-centered'
                title={t('common:pendingRegistration')}
                startSubtitle={t('common:incompleteRegistration')}
            />
            <div className={styles.wrap}>
                <Alert
                    className='mb-40'
                    type='info'
                    startTitle={t('common:checkEmail')}
                    description={<AlertDescription />}
                />
                <div className={styles.actions}>
                    <a href={`/${getSearch()}`} className={styles.link}>{t('common:backToLogin')}</a>
                </div>
            </div>
        </div>
    );
};

export default ResetPasswordConfirm;
