import { h } from 'preact';
import { useCallback } from 'preact/hooks';
import { useTranslation } from 'react-i18next';
import { classNames, Icon } from '@payhawk/hawky-react';
import { useAuth } from '../../auth';
import styles from './CodeResent.module.scss';

const CodeResent: preact.FunctionalComponent = () => {
    const [_authState, authActions] = useAuth();
    const { t } = useTranslation(['common']);

    const onContactSupport = useCallback(() => {
        authActions.contactSupport();
        authActions.cancelSecurityCodeChange();
    }, [authActions]);

    return (
        <div class={classNames('g-12-8', styles.container)}>
            <div className='g-col-1'>
                <Icon name='circle-info' />
            </div>
            <div className={classNames('g-col-10', 'd-flex-col', 'text-left')}>
                <div className={classNames('mb-8', styles.title)}>{t('common:recoveryCodeWasResent')}</div>
                <div className={styles.contactUs}>
                    <span>{t('common:stillNotReceiving')}</span>
                    <span className={styles.link} onClick={onContactSupport}> {t('common:contactUs')}</span>
                </div>
            </div>
            <div className='g-col-1'/>
        </div>
    );
};

export default CodeResent;
