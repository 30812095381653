import { h } from 'preact';
import { useCallback, useState } from 'preact/hooks';
import { KeyboardEvent, } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../auth';

import {
    Button,
    InputState,
    ITextInputOnChangeProps,
} from '@payhawk/hawky-react';

import { useUpdateHeight } from '../../utils/useUpdateHeight';
import styles from './CheckPassword.module.scss';
import { TextInputAutoFocus } from '../../utils/TextInputAutoFocus';

const PasswordInput = 'password';

const CheckPassword = () => {
    const [authState, authActions] = useAuth();
    const [password, setPassword] = useState('');

    const { t } = useTranslation(['common']);

    useUpdateHeight();

    const onInputChange = useCallback(({ name, value }: ITextInputOnChangeProps) => {
        if (name === PasswordInput) {
            setPassword(value);
        }
    }, [setPassword]);

    const onSubmit = useCallback(async () => {
        if (!password) {
            return;
        }
        await authActions.checkPassword(password);
    }, [authState.email, password, authActions]);

    const onEnter = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            onSubmit();
        }
    }, [onSubmit]);

    const onCancel = useCallback(() => {
        authActions.cancelSecurityCodeChange();
    }, []);

    return (
        <div class={styles.container}>
            <div className={styles.title}>{t('common:enterPassword')}</div>
            <div className={styles.subtitle}>{t('common:beforeYouContinueEnterYourPassword')}</div>
            <div class={styles.wrap}>
                <TextInputAutoFocus
                    className='mt-24'
                    label={t('common:password')}
                    type='password'
                    id={PasswordInput}
                    name={PasswordInput}
                    value={password}
                    onChange={onInputChange}
                    onKeyDown={onEnter}
                    isReadOnly={authState.isSigningIn}
                    placeholder={t('common:enterPassword')}
                    isRequired
                    state={authState.passwordCheckSuccess === false ? InputState.Error : InputState.None}
                    stateText={authState.passwordCheckSuccess === false ? t('common:incorrectEmailOrPassword') : ''}
                />

                <div class={styles.buttons}>
                    <Button
                        className='mt-32'
                        label={t('common:continue')}
                        isExpanded
                        isDisabled={authState.passwordCheckLoading}
                        isLoading={authState.passwordCheckLoading}
                        onClick={onSubmit}
                    />

                    <Button
                        className='mt-32'
                        label={t('common:cancel')}
                        isExpanded
                        skin={'secondary'}
                        onClick={onCancel}
                    />
                </div>
            </div>
        </div>
    );
};

export default CheckPassword;
