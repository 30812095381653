import { CognitoAccessToken, CognitoUser } from 'amazon-cognito-identity-js';
import { IMultiFactorAuthChallenge, IUserAuthenticationInfo, IUserInfo, PushChallengeStatus } from '../../services';
import { AuthenticationType } from '../../utils/Browser';

export interface IMultiFactorAuthActions {
    onRestrictedSessionCreated(restrictedUser: CognitoUser): Promise<void>;

    initMultiFactorAuth(restrictedSession: IRestrictedSession, authType: AuthenticationType): Promise<void>;
    resolveChallenge(challenge: IMultiFactorAuthChallenge, restrictedSession: IRestrictedSession): Promise<void>;
    resolveSmsChallenge(challenge: IMultiFactorAuthChallenge, restrictedSession: IRestrictedSession, code: string): Promise<void>;
    getPushChallengeStatus(challenge: IMultiFactorAuthChallenge, restrictedSession: IRestrictedSession): Promise<PushChallengeStatus>;
    declinePushChallenge(): void;

    updateUserAuthInfo(restrictedSession: IRestrictedSession): Promise<void>;
    getUserInfo(restrictedUser: CognitoUser): Promise<IUserInfo | undefined>;
    updateUserInfo(restrictedUser: CognitoUser, user: IUserInfo, patch: Pick<IUserInfo, 'familyName' | 'givenName' | 'phoneNumber'>): Promise<IUserInfo>;
    initPhoneVerification(restrictedUser: CognitoUser): Promise<void>;
    verifyPhone(restrictedUser: CognitoUser, code: string): Promise<void>;

    userSignedIn(userSession: IUserSession): void;
}

export interface IMultiFactorAuthState {
    restrictedSession?: IRestrictedSession;
    userSession?: IUserSession;
    userOnboardingInfo?: IUserInfo;
    isInitiating: boolean;
    isResolving: boolean;
    authType?: AuthenticationType;
    challenge?: IMultiFactorAuthChallenge;
    challengeError?: IChallengeError;
}

export enum ChallengeErrorType {
    Unknown,
    Expired,
    InvalidCode,
}

export interface IChallengeError {
    type: ChallengeErrorType;
    error?: any;
}

export interface IRestrictedSession {
    user: CognitoUser;
    userAuthInfo: IUserAuthenticationInfo;
}
