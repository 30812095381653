import { useEffect } from 'preact/hooks';
import { useAuth } from '../auth';

export const useUpdateHeight = () => {
    const [, authActions] = useAuth();

    useEffect(() => {
        setTimeout(() => authActions.changeHeight(document.documentElement.scrollHeight), 0);
    }, [document.documentElement.scrollHeight]);
};
