import { useTranslation } from 'react-i18next';
import { h } from 'preact';

import {
    Button,
    ContentHeader,
    IButtonClickEvent,
    InputState,
    ITextInputOnChangeProps,
    SelectInput,
    TextInput,
} from '@payhawk/hawky-react';

import { useState } from 'preact/hooks';
import { useAuth } from '../../../auth';

import styles from './CompleteUserProfile.module.scss';
import { Countries, validatePhoneNumber } from '../../../utils';
import { IUserInfo } from '../../../services';
import { TextInputAutoFocus } from '../../../utils/TextInputAutoFocus';

interface ICompleteUserProfileProps {
    userInfo: IUserInfo;
    onUserUpdated: (user: IUserInfo) => {};
}

export const CompleteUserProfile = ({ onUserUpdated, userInfo }: ICompleteUserProfileProps) => {
    const { t } = useTranslation(['common', 'auth', 'user']);
    const [ authState, _, multiFactorAuthActions ] = useAuth();
    const { restrictedSession, challenge } = authState.multiFactorAuth;

    const [givenName, setGivenName] = useState<string>(userInfo.givenName || '');
    const [familyName, setFamilyName] = useState<string>(userInfo.familyName || '');
    const [phoneCode, setPhoneCode] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [showValidation, setShowValidation] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const phoneNumber = `+${phoneCode}${phone.replace(/^0/, '').trim()}`;

    if (!challenge) {
        return null;
    }

    const onSubmit = async ({ event: e }: IButtonClickEvent) => {
        e.preventDefault();
        setShowValidation(true);

        if (
            !!givenNameValidation
            || !!familyNameValidation
            || !!phoneValidation
            || !!phoneCodeValidation
            || !restrictedSession
        ) {
            return;
        }

        setError('');
        setIsSaving(true);
        setShowValidation(false);

        try {
            const user = await multiFactorAuthActions.updateUserInfo(restrictedSession.user, userInfo, {
                givenName,
                familyName,
                phoneNumber,
            });

            onUserUpdated(user);
        } catch (e: any) {
            setError(e.message || '');
        }

        setIsSaving(false);
    };

    const givenNameValidation: string | undefined = !givenName?.trim() ? t('user:personalInfo.firstNameIsRequired') : undefined;
    const familyNameValidation: string | undefined = !familyName?.trim() ? t('user:personalInfo.lastNameIsRequired') : undefined;
    const phoneCodeValidation: string | undefined = !phoneCode?.trim() ? t('user:personalInfo.countryCodeRequired') : undefined;
    const phoneValidation: string | undefined = !phone?.trim()
        ? t('user:personalInfo.phoneIsRequired')
        : validatePhoneNumber(phoneNumber) ? undefined : t('user:personalInfo.phoneIsIncorrect');

    const onPhoneCodeChangeHandler = (value?: IOption) => {
        setPhoneCode(value?.value ?? '');
    };

    const onGiveNameChangeHandler = ({ value }: ITextInputOnChangeProps) => setGivenName(value);
    const onFamilyNameChangeHandler = ({ value }: ITextInputOnChangeProps) => setFamilyName(value);
    const onPhoneChangeHandler = ({ value }: ITextInputOnChangeProps) => setPhone(value);

    return (
        <div className={styles.container}>
            <ContentHeader
                type='large-centered'
                title={t('user:personalInfo.completeUserProfile')}
                className='mb-40'
            />
            <div className='w-100'>
                <TextInputAutoFocus
                    className='mb-24'
                    name='given_name'
                    value={givenName}
                    label={t('common:firstName')}
                    placeholder={t('common:firstNamePlaceholder')}
                    onChange={onGiveNameChangeHandler}
                    state={showValidation && givenNameValidation ? InputState.Error : InputState.None}
                    stateText={givenNameValidation}
                    isRequired
                />
                <TextInput
                    className='mb-24'
                    name='family_name'
                    value={familyName}
                    label={t('common:lastName')}
                    placeholder={t('common:lastNamePlaceholder')}
                    onChange={onFamilyNameChangeHandler}
                    state={showValidation && familyNameValidation ? InputState.Error : InputState.None}
                    stateText={familyNameValidation}
                    isRequired
                />
            </div>
            <div className='g-6-40 mb-40'>
                <SelectInput
                    className='g-col-2'
                    value={phoneCode}
                    label={t('user:personalInfo.countryCode')}
                    items={options}
                    state={showValidation && phoneCodeValidation ? InputState.Error : InputState.None}
                    stateText={phoneCodeValidation}
                    onSelect={onPhoneCodeChangeHandler}
                    placeholder={(options.find(c => c.value === '44'))?.label}
                    model={{
                        itemLabelField: 'itemLabel',
                    }}
                    dataType='array'
                />
                <TextInput
                    className='g-col-4'
                    name='phone'
                    value={phone}
                    label={t('user:personalInfo.phoneNumber')}
                    placeholder={t('user:personalInfo.phoneNumberPlaceholder')}
                    onChange={onPhoneChangeHandler}
                    state={showValidation && phoneValidation ? InputState.Error : InputState.None}
                    stateText={phoneValidation}
                    isRequired
                />
            </div>
            <Button
                label={t('common:continue')}
                isExpanded
                isLoading={isSaving}
                onClick={onSubmit}
            />
        </div>
    );
};

const options: IOption[] = Countries.filter(c => c.callingCodes.length).map(c => {
    const code = c.callingCodes[0];

    return { id: code, value: code, itemLabel: `${c.name} (+${code})`, label: `+${code}` };
});

interface IOption {
    id: string;
    value: string;
    label: string;
    itemLabel: string;
}

CompleteUserProfile.displayName = 'CompleteUserProfile';
