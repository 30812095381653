import { Button, ContentHeader } from '@payhawk/hawky-react';
import { FunctionComponent, h } from 'preact';

import styles from './MultiFactorAuth.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { route } from 'preact-router';
import { useAuth } from '../../auth';

export const LostTrustedDevice = () => {
    const { t } = useTranslation(['auth', 'common']);
    const [ _, authActions ] = useAuth();

    const onContactSupportHandler = () => {
        authActions.contactSupport();
    };

    const onGoBackHandler = async () => {
        route('/multi-factor');
    };

    return (
        <div className={styles.container}>
            <div className='d-flex align-items-center flex-column'>
                <ContentHeader
                    type='large-centered'
                    title={t('common:contactUsForSupport')}
                    startSubtitle={(
                        <div>
                            <Trans ns='auth' i18nKey='mfa.pushAuth.lostDeviceRecoveryDescription' components={{
                                a: <a href='/multi-factor/lost-trusted-device' title='Payhawk Support' onClick={onContactSupportHandler} className='link' />
                            }} />
                        </div>
                    )}
                    className='mb-32 mt-32'
                />

                <div>
                    <Button label={t('common:back')} onClick={onGoBackHandler} skin='tertiary' />
                </div>
            </div>
        </div>
    );
};

LostTrustedDevice.displayName = 'LostTrustedDevice';
