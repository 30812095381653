import { h } from 'preact';
import { useCallback, useState } from 'preact/hooks';
import { useTranslation } from 'react-i18next';
import { CognitoErrorCode, getUnexpectedError } from '../../auth';
import { useServices } from '../../services';
import { isEmpty } from '../../utils/ArgumentValidations';
import { isRequestErrorInstance } from '../../utils/Error';

import {
    Alert,
    Button,
    ContentHeader,
    InputState,
    ITextInputOnChangeProps,
    TextInput
} from '@payhawk/hawky-react';
import { Location } from 'history';
import { TextInputAutoFocus } from '../../utils/TextInputAutoFocus';

interface IState {
    oldPassword: string;
    newPassword: string;
    newPasswordConfirm: string;
    isChangingPassword: boolean;
    oldPasswordError?: string;
    newPasswordError?: string;
    newPasswordConfirmError?: string;
    isSuccess: boolean;
}

interface IChangePasswordParams {
    location: Location;
}

const ChangePassword: preact.FunctionalComponent<IChangePasswordParams> = ({ location }) => {
    const { authService, messageService } = useServices();
    const [state, setState] = useState<IState>({
        oldPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
        isChangingPassword: false,
        oldPasswordError: undefined,
        newPasswordError: undefined,
        newPasswordConfirmError: undefined,
        isSuccess: false,
    });

    const { t } = useTranslation(['common']);
    const UNEXPECTED_ERROR = getUnexpectedError(t);

    const onInputChange = useCallback(({ name, value }: ITextInputOnChangeProps) => {
        switch (name) {
            case 'old-password':
                setState((s) => ({ ...s, oldPassword: value }));
                break;
            case 'new-password':
                setState((s) => ({ ...s, newPassword: value }));
                break;
            case 'confirm-new-password':
                setState((s) => ({ ...s, newPasswordConfirm: value }));
                break;
            default:
                break;
        }
    }, [setState]);

    const changePassword = useCallback(async (e: h.JSX.TargetedEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (isEmpty(state.oldPassword)) {
            setState(s => ({ ...s, oldPasswordError: t('common:oldPasswordRequired') }));

            return;
        }

        if (isEmpty(state.newPassword)) {
            setState(s => ({ ...s, newPasswordError: t('common:newPasswordRequired') }));

            return;
        }

        if (isEmpty(state.newPasswordConfirm)) {
            setState(s => ({ ...s, newPasswordConfirmError: t('common:confirmNewPasswordRequired') }));

            return;
        }

        if (state.newPassword !== state.newPasswordConfirm) {
            setState(s => ({ ...s, newPasswordError: undefined, newPasswordConfirmError: t('common:passwordsDontMatch') }));

            return;
        }

        setState(s => ({ ...s, isChangingPassword: true }));

        try {
            const session = await messageService.getUserSession();
            if (!session) {
                setState(s => ({ ...s, oldPasswordError: t('common:userNotAuthenticated') }));

                return;
            }

            await authService.changePassword(state.oldPassword, state.newPassword, session);

            setState({
                newPassword: '',
                oldPassword: '',
                newPasswordConfirm: '',
                oldPasswordError: undefined,
                newPasswordError: undefined,
                newPasswordConfirmError: undefined,
                isChangingPassword: false,
                isSuccess: true,
            });
        } catch (e) {
            let error = '';
            if (isRequestErrorInstance(e)) {
                switch (e.code) {
                    case CognitoErrorCode.LimitExceededException:
                    case CognitoErrorCode.TooManyRequestsException:
                    case CognitoErrorCode.InvalidParameterException:
                    case CognitoErrorCode.InvalidPasswordException:
                        error = e.message || '';
                        setState(s => ({
                            ...s,
                            newPasswordError: error,
                            newPasswordConfirmError: undefined,
                            isChangingPassword: false,
                            isSuccess: false,
                        }));
                        break;
                    case CognitoErrorCode.NotAuthorizedException:
                        error = t('common:incorrectOldPassword');
                        setState(s => ({
                            ...s,
                            oldPasswordError: error,
                            newPasswordError: undefined,
                            newPasswordConfirmError: undefined,
                            isChangingPassword: false,
                            isSuccess: false,
                        }));
                        break;
                    default:
                        error = UNEXPECTED_ERROR;
                        setState(s => ({
                            ...s,
                            oldPasswordError: error,
                            newPasswordError: undefined,
                            newPasswordConfirmError: undefined,
                            isChangingPassword: false,
                            isSuccess: false,
                        }));
                        break;
                }
            }

        }
    }, [state, setState, authService, messageService]);

    const query = new URLSearchParams(location.search);
    const showTitle = !query.get('hideTitle');

    return (
        <div>
            {
                showTitle && (
                    <ContentHeader
                        title={t('common:changePassword')}
                        type={'large'}
                        className='mb-72'
                    />
                )
            }
            <form onSubmit={changePassword}>
                <div class='g-12-40'>
                    <div class='g-col-4'>
                        <TextInputAutoFocus
                            label={t('common:oldPassword')}
                            id='old-password'
                            name='old-password'
                            type='password'
                            value={state.oldPassword}
                            onChange={onInputChange}
                            isReadOnly={state.isChangingPassword}
                            placeholder={t('common:enterCurrentPasswordPlaceholder')}
                            isRequired
                            state={state.oldPasswordError ? InputState.Error : InputState.None}
                            stateText={state.oldPasswordError ? state.oldPasswordError : ''}
                        />

                        <TextInput
                            className='mt-24'
                            label={t('common:newPassword')}
                            placeholder={t('common:enterNewPassword')}
                            helperText={t('common:passwordPlaceholder')}
                            id='new-password'
                            type='password'
                            name='new-password'
                            value={state.newPassword}
                            onChange={onInputChange}
                            isReadOnly={state.isChangingPassword}
                            isRequired
                            state={state.newPasswordError ? InputState.Error : InputState.None}
                            stateText={state.newPasswordError ? state.newPasswordError : ''}
                        />

                        <TextInput
                            className='mt-24'
                            label={t('common:confirmNewPassword')}
                            placeholder={t('common:reEnterNewPassword')}
                            id='confirm-new-password'
                            name='confirm-new-password'
                            type='password'
                            value={state.newPasswordConfirm}
                            onChange={onInputChange}
                            isReadOnly={state.isChangingPassword}
                            isRequired
                            state={state.newPasswordConfirmError ? InputState.Error : InputState.None}
                            stateText={state.newPasswordConfirmError ? state.newPasswordConfirmError : ''}
                        />
                    </div>
                    {
                        state.isSuccess
                            ? <div class='g-col-7 g-start-1 mt-32'>
                                <Alert type='success' description={t('common:passwordChangedSuccessfully')}/>
                            </div>
                            : null
                    }
                    <div />
                </div>
                <Button
                    className='mt-32'
                    label={t('common:changePassword')}
                    type='submit'
                    isDisabled={state.isChangingPassword}
                    isLoading={state.isChangingPassword}
                />
            </form>
        </div>
    );
};

export default ChangePassword;
