import { h } from 'preact';

import { useEffect, useState } from 'preact/hooks';
import { useAuth } from '../../auth';
import { PushAuthSetup } from './PushAuthSetup';

const statusPollingIntervalMiliseconds = 5 * 1000;

export const PushAuthSetupRequired = () => {
    const [ authState, _authActions, multiFactorAuthActions ] = useAuth();
    const { restrictedSession, challenge, challengeError } = authState.multiFactorAuth;
    const [ _statusPolling, setStatusPolling ] = useState<NodeJS.Timeout | undefined>();

    useEffect(() => {
        if (!challenge || challenge.data.type !== 'PAYHAWK_PUSH_SETUP' || !restrictedSession) {
            return;
        }

        if (restrictedSession && challenge?.data.type === 'PAYHAWK_PUSH_SETUP') {
            if (restrictedSession.userAuthInfo.configuredFactors.pushAuthentication) {
                void multiFactorAuthActions.resolveChallenge(challenge, restrictedSession);
            } else {
                setStatusPolling((s) => {
                    if (s) {
                        clearTimeout(s);
                    }
        
                    // The updateUserAuthInfo will result in a new restrictedSession, which in turn will trigger the effect again
                    return setTimeout(async () => await multiFactorAuthActions.updateUserAuthInfo(restrictedSession), statusPollingIntervalMiliseconds);
                });
            }
        }

        return () => {
            setStatusPolling((s) => {
                if (s) {
                    clearTimeout(s);
                }

                return undefined;
            });
        };
    }, [challenge, restrictedSession]);

    const hasSetupPushAuthChallenge: boolean = !!(restrictedSession && challenge?.data.type === 'PAYHAWK_PUSH_SETUP');

    if (!hasSetupPushAuthChallenge) {
        return null;
    }

    return <PushAuthSetup />;
};

PushAuthSetupRequired.displayName = 'PushAuthSetupRequired';
