import { h } from 'preact';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { classNames, Icon } from '@payhawk/hawky-react';
import { copyToClipboard } from '../../utils/Browser';
import styles from './CopyableText.module.scss';

interface IProps {
    label: string;
    value?: string | number | JSX.Element;
    formattedValue?: string | JSX.Element;
    className?: string;
    labelClassName?: string;
    valueClassName?: string;
    showCopiedOverIcon?: boolean;
}

export const CopyableText = ({ label, value = '', formattedValue, className, labelClassName, valueClassName, showCopiedOverIcon }: IProps) => {
    const { t } = useTranslation(['common']);
    const [showCopied, setShowCopied] = useState(false);
    const [visibleCopyIcon, setVisibleCopyIcon] = useState(false);
    const onClick = useCallback(
        () => {
            copyToClipboard(value.toString());
            setShowCopied(true);
            setVisibleCopyIcon(false);
            setTimeout(() => setShowCopied(false), 1000);
        }, [value],
    );
    const showCopyIcon = useCallback(() => setVisibleCopyIcon(true), []);
    const hideCopyIcon = useCallback(() => setVisibleCopyIcon(false), []);
    
    return (
        <div className={classNames(styles.container, className)} onClick={onClick} onMouseOver={showCopyIcon} onMouseOut={hideCopyIcon}>
            <div className={classNames(styles.label, labelClassName)}>{label}</div>
            <div className={classNames(styles.value, valueClassName)}>
                {showCopied && !showCopiedOverIcon ?
                    <div className={classNames(styles.copiedText)}>
                        <Icon className={styles.checkIcon} name='circle-check' />{t('common:card.copied')}
                    </div> :
                    <div>{formattedValue || value}</div>
                }
                {
                    showCopied && showCopiedOverIcon ?
                    <div className={classNames(styles.copiedText)}>
                        <Icon className={styles.checkIcon} name='circle-check' />{t('common:card.copied')}
                    </div> :
                    <Icon className={classNames(visibleCopyIcon ? styles.copyIcon : styles.copyIconHidden)} onClick={onClick} name={'copy'} />
                }
            </div>
        </div>
    );
};
