import { h } from 'preact';
import { useTranslation } from 'react-i18next';
import { useUpdateHeight } from '../../utils/useUpdateHeight';
import styles from './InitSecurityCode.module.scss';

const SecurityCodeError: preact.FunctionalComponent = () => {
    const { t } = useTranslation(['common']);

    useUpdateHeight();

    return (
        <div class={styles.container} key='init-security-code'>
            <div>
                {t('common:networkErrorMessage')}
            </div>
        </div>
    );
};

export default SecurityCodeError;
