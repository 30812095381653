import Axios, { AxiosInstance } from 'axios';

export function createApiClient(baseURL: string) {
    const client = Axios.create({
        baseURL,
    });

    return client;
}

export interface IApiClient extends AxiosInstance { }