import { AuthActionType, IAuthStateAction } from '../types';
import { IMultiFactorAuthState } from './contracts';

export function multiFactorAuthReducer(state: IMultiFactorAuthState, action: IAuthStateAction): IMultiFactorAuthState {
    switch (action.type) {
        case AuthActionType.MFA_CHALLENGE_INIT:
            return { ...state, isInitiating: true, authType: action.data, challenge: action.data === state.authType ? state.challenge : undefined, isResolving: false, challengeError: undefined };
        case AuthActionType.MFA_CHALLENGE_INIT_SUCCESS:
            return { ...state, isInitiating: false, challenge: action.data, challengeError: undefined };
        case AuthActionType.MFA_CHALLENGE_INIT_FAIL:
            return { ...state, isInitiating: false, challengeError: action.data };

        case AuthActionType.MFA_CHALLENGE_RESOLVE:
            return { ...state, isResolving: true, challengeError: undefined };
        case AuthActionType.MFA_CHALLENGE_RESOLVE_SUCCESS:
            return { ...state, isResolving: false, challenge: action.data, challengeError: undefined };
        case AuthActionType.MFA_CHALLENGE_RESOLVE_FAIL:
            return { ...state, isResolving: false, challengeError: action.data };

        case AuthActionType.RESTRICTED_SESSION_CREATED:
            return { ...state, restrictedSession: action.data, authType: undefined, challenge: undefined, userSession: undefined };
        case AuthActionType.RESTRICTED_SESSION_UPDATE:
            return { ...state, restrictedSession: action.data };
        case AuthActionType.FULL_SESSION_CREATED:
            return { ...state, userSession: action.data, restrictedSession: undefined, authType: undefined, challenge: undefined, challengeError: undefined };

        case AuthActionType.CLEAN_SIGN_IN:
            return { isInitiating: false, isResolving: false };

        default:
            return { ...state };
    }
}