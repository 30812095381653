import de from './de';
import en from './en';
import es from './es';
import fr from './fr';
import it from './it';
import nl from './nl';
import bg from './bg';
import pt from './pt';
import fi from './fi';

export const defaultNS = 'common';
export const resources = {
    en,
    de,
    es,
    fr,
    it,
    nl,
    bg,
    pt,
    fi,
} as const;

export const TFunctionDefault = (...args: any[]) => '';
