import { AuthActionType, IAuthState, IAuthStateAction } from './types';

export default function authReducer(state: IAuthState, action: IAuthStateAction): IAuthState {
    switch (action.type) {
        case AuthActionType.SET_USERNAME:
            return { ...state, email: action.data || '' };

        case AuthActionType.SIGN_IN:
            return { ...state, isSigningIn: true, signInError: undefined };
        case AuthActionType.SIGN_IN_SUCCESS:
            return { ...state, isSigningIn: false, signInError: undefined };
        case AuthActionType.SIGN_IN_FAIL:
            return { ...state, isSigningIn: false, signInError: action.data };

        case AuthActionType.EXTERNAL_SIGN_IN:
            return { ...state, isSigningInWithExternalProvider: true, externalSignInError: undefined };
        case AuthActionType.EXTERNAL_SIGN_IN_SUCCESS:
            return { ...state, isSigningInWithExternalProvider: false, externalSignInError: undefined };
        case AuthActionType.EXTERNAL_SIGN_IN_FAIL:
            return { ...state, isSigningInWithExternalProvider: false, externalSignInError: action.data };

        case AuthActionType.CHECK_EMAIL:
            return { ...state, isCheckingEmail: true, checkEmailError: undefined, signInError: undefined, externalSignInError: undefined };
        case AuthActionType.CHECK_EMAIL_SUCCESS:
            return { ...state, isCheckingEmail: false, checkEmailError: undefined };
        case AuthActionType.CHECK_EMAIL_FAIL:
            return { ...state, isCheckingEmail: false, checkEmailError: action.data };

        case AuthActionType.RESET_PASSWORD:
            return { ...state, isResettingPassword: true, resetPasswordError: undefined };
        case AuthActionType.RESET_PASSWORD_SUCCESS:
            return { ...state, isResettingPassword: false, resetPasswordError: undefined };
        case AuthActionType.RESET_PASSWORD_FAIL:
            return { ...state, isResettingPassword: false, resetPasswordError: action.data };

        case AuthActionType.SIGN_UP:
            return { ...state, isSigningUp: true, signUpError: undefined };
        case AuthActionType.SIGN_UP_SUCCESS:
            return { ...state, isSigningUp: false, signUpError: undefined, userId: action.data };
        case AuthActionType.SIGN_UP_FAIL:
            return { ...state, isSigningUp: false, signUpError: action.data };

        case AuthActionType.VERIFY_SIGN_UP:
            return { ...state, isSigningUp: true, signUpError: undefined };
        case AuthActionType.VERIFY_SIGN_UP_SUCCESS:
            return { ...state, isSigningUp: false, signUpError: undefined, tempPassword: action.data.tempPassword };
        case AuthActionType.VERIFY_SIGN_UP_FAIL:
            return { ...state, isSigningUp: false, signUpError: action.data };

        case AuthActionType.VERIFY_INVITATION:
            return { ...state, isSigningUp: true, signUpError: undefined };
        case AuthActionType.VERIFY_INVITATION_SUCCESS:
            return { ...state, isSigningUp: false, signUpError: undefined, userId: action.data.userId, email: action.data.email, tempPassword: action.data.tempPassword };
        case AuthActionType.VERIFY_INVITATION_FAIL:
            return { ...state, isSigningUp: false, signUpError: action.data?.message, registrationError: action.data?.type };
        case AuthActionType.CONFIRM_SIGN_UP:
            return { ...state, isSigningUp: true, signUpError: undefined, tempPassword: action.data?.password, isLoginWithTempPassword: action.data?.isLoginWithTempPassword };
        case AuthActionType.CONFIRM_SIGN_UP_SUCCESS:
            return { ...state, isSigningUp: false, signUpError: undefined };
        case AuthActionType.CONFIRM_SIGN_UP_FAIL:
            return { ...state, isSigningUp: false, signUpError: action.data };

        case AuthActionType.RESEND_CONFIRMATION_CODE:
            return { ...state, isResendingVerificationCode: true, signUpError: undefined };
        case AuthActionType.RESEND_CONFIRMATION_CODE_SUCCESS:
            return { ...state, isResendingVerificationCode: false, signUpError: undefined };
        case AuthActionType.RESEND_CONFIRMATION_CODE_FAIL:
            return { ...state, isResendingVerificationCode: false, signUpError: action.data };

        case AuthActionType.CLEAN_SIGN_UP:
            return { ...state, isSigningUp: false, signUpError: undefined, userId: undefined, tempPassword: undefined };
        case AuthActionType.CLEAN_SIGN_IN:
            return { ...state, isSigningUp: false, signUpError: undefined, userId: undefined, tempPassword: undefined, email: '', signInError: undefined };

        case AuthActionType.GET_SECURITY_CODE:
            return { ...state, isGettingSecurityCode: true, hasSecurityCode: false };
        case AuthActionType.GET_SECURITY_CODE_SUCCESS:
            return { ...state, isGettingSecurityCode: false, hasSecurityCode: action.data };
        case AuthActionType.GET_SECURITY_CODE_FAIL:
            return { ...state, isGettingSecurityCode: false, hasSecurityCodeError: action.data };
        case AuthActionType.VERIFY_SECURITY_CODE:
            return { ...state, isVerifyingSecurityCode: true, verifyCodeError: undefined, isCodeValid: undefined };
        case AuthActionType.VERIFY_SECURITY_CODE_SUCCESS:
            return { ...state, isVerifyingSecurityCode: false, verifyCodeError: undefined, isCodeValid: action.data.isValid };
        case AuthActionType.VERIFY_SECURITY_CODE_FAIL:
            return { ...state, isVerifyingSecurityCode: false, verifyCodeError: action.data, isCodeValid: false };

        case AuthActionType.SET_SECURITY_CODE:
            return { ...state, isSendingNewSecurityCode: true, setNewCodeError: undefined };
        case AuthActionType.SET_SECURITY_CODE_SUCCESS:
            return { ...state, isSendingNewSecurityCode: false, setNewCodeError: undefined, hasSecurityCode: true, securityCode: action.data };
        case AuthActionType.SET_SECURITY_CODE_FAIL:
            return { ...state, isSendingNewSecurityCode: false, setNewCodeError: action.data };

        case AuthActionType.RESET_SECURITY_CODE:
            return { ...state, isSendingResetSecurityCode: true, resetSecurityCodeError: undefined };
        case AuthActionType.RESET_SECURITY_CODE_SUCCESS:
            return { ...state, isSendingResetSecurityCode: false, resetSecurityCodeError: undefined, isCodeReset: true };
        case AuthActionType.RESET_SECURITY_CODE_FAIL:
            return { ...state, isSendingResetSecurityCode: false, resetSecurityCodeError: action.data, isCodeReset: false };

        case AuthActionType.GET_CARD_DETAILS:
            return { ...state, isGettingCardDetails: true, cardDetailsError: undefined };
        case AuthActionType.GET_CARD_DETAILS_SUCCESS:
            return { ...state, isGettingCardDetails: false, cardDetailsError: undefined, cardDetails: action.data, remainingRetryCount: undefined, hasExceededMaximumRetries: false };
        case AuthActionType.GET_CARD_DETAILS_FAIL:
            return { ...state, isGettingCardDetails: false, cardDetailsError: action.data, hasExceededMaximumRetries: false, remainingRetryCount: undefined };
        case AuthActionType.GET_CARD_DETAILS_FAIL_MAXIMUM_RETRIES_EXCEEDED:
            return { ...state, isGettingCardDetails: false, cardDetailsError: undefined, hasExceededMaximumRetries: true, remainingRetryCount: undefined };
        case AuthActionType.GET_CARD_DETAILS_FAIL_REMAINING_RETRY_COUNT:
            return { ...state, isGettingCardDetails: false, cardDetailsError: undefined, remainingRetryCount: action.data };
        case AuthActionType.SHOW_PASSWORD_CHECK:
            return { ...state, isShowPasswordCheck: true };
        case AuthActionType.PASSWORD_CHECK_LOADING:
            return { ...state, isShowPasswordCheck: true, passwordCheckLoading: true };
        case AuthActionType.PASSWORD_CHECK_SUCCESS:
            return { ...state, isShowPasswordCheck: false, passwordCheckLoading: false, passwordCheckSuccess: true };
        case AuthActionType.PASSWORD_CHECK_FAIL:
            return { ...state, isShowPasswordCheck: true, passwordCheckLoading: false, passwordCheckSuccess: false };

        default:
            return state;
    }
}