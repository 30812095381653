import { h } from 'preact';

import styles from './MultiFactorAuth.module.scss';
import { Button, ContentHeader, CountdownTimer } from '@payhawk/hawky-react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../auth';
import { useCallback, useEffect, useState } from 'preact/hooks';
import { AuthenticationType } from '../../utils/Browser';
import { route } from 'preact-router';
import { IPushCognitoChallenge } from '../../services';

const statusPollingIntervalMiliseconds = 3 * 1000;

export const PushAuthVerify = () => {
    const { t } = useTranslation(['auth']);

    const [ authState, _authActions, multiFactorAuthActions ] = useAuth();
    const { isInitiating, restrictedSession, challenge } = authState.multiFactorAuth;
    const [ hasExpired, setHasExpired ] = useState<boolean>(false);
    const [ _statusPolling, setStatusPolling ] = useState<NodeJS.Timeout | undefined>();
    const [ durationInMiliseconds, setDurationInMiliseconds ] = useState<number | undefined>();

    const onTimeoutHandler = useCallback(() => {
        setStatusPolling((s) => {
            if (s) {
                clearTimeout(s);
            }

            return undefined;
        });

        setHasExpired(true);
    }, []);

    const pollStatus = useCallback(async () => {
        if (!challenge || !restrictedSession) {
            return;
        }

        const status = await multiFactorAuthActions.getPushChallengeStatus(challenge, restrictedSession);

        switch(status) {
            case 'approved':
                await multiFactorAuthActions.resolveChallenge(challenge, restrictedSession);
                break;
            case 'pending':
                setStatusPolling((s) => {
                    if (s) {
                        clearTimeout(s);
                    }
        
                    return setTimeout(pollStatus, statusPollingIntervalMiliseconds);
                });
                break;
            case 'denied':
                multiFactorAuthActions.declinePushChallenge();
                break;
            case 'expired':
                onTimeoutHandler();
                break;

        }
    }, [challenge, restrictedSession, onTimeoutHandler]);

    useEffect(() => {
        if (!challenge || challenge.data.type !== 'PAYHAWK_PUSH') {
            return;
        }

        setStatusPolling((s) => {
            if (s) {
                clearTimeout(s);
            }

            return setTimeout(pollStatus, statusPollingIntervalMiliseconds);
        });

        return () => {
            setStatusPolling((s) => {
                if (s) {
                    clearTimeout(s);
                }

                return undefined;
            });
        };
    }, [challenge, pollStatus]);

    useEffect(() => {
        const pushChallenge = challenge?.data as IPushCognitoChallenge | undefined;

        if (!pushChallenge?.expiresAt) {
            return;
        }

        const expiresAt = new Date(pushChallenge.expiresAt);
        const timeRemaining = expiresAt.getTime() - new Date().getTime();

        if (timeRemaining > 0) {
            setDurationInMiliseconds(timeRemaining);
        } else {
            setDurationInMiliseconds(0);
            onTimeoutHandler();
        }
    }, [challenge]);

    const hasPushAuthChallenge: boolean = !!(restrictedSession && challenge?.data.type === 'PAYHAWK_PUSH');

    if (!hasPushAuthChallenge || durationInMiliseconds === undefined) {
        return null;
    }

    const canUseSmsAuthFactor: boolean = !!(restrictedSession?.userAuthInfo?.allowedFactors.sms && restrictedSession.userAuthInfo.configuredFactors.sms);

    const onUseSmsHandler = async () => {
        if (!canUseSmsAuthFactor || !restrictedSession) {
            return;
        }

        await multiFactorAuthActions.initMultiFactorAuth(restrictedSession, AuthenticationType.Sms);
    };

    const onLostTrustedDevice = () => {
        route('/multi-factor/lost-trusted-device');
    };

    const retryPushAuthHandler = async () => {
        if (!restrictedSession) {
            return;
        }

        await multiFactorAuthActions.initMultiFactorAuth(restrictedSession, AuthenticationType.PushAuth);
    };

    const footerActions = (
        <div className={`d-flex justify-content-center`}>
            {canUseSmsAuthFactor && (
                <Button
                    label={t('auth:mfa.sms.useSmsAuth')}
                    skin='link'
                    size='small'
                    className='mr-12'
                    onClick={onUseSmsHandler} />
            )}
            <Button
                label={t('auth:mfa.pushAuth.lostDevice')}
                skin='link'
                size='small'
                onClick={onLostTrustedDevice} />
        </div>);

    if (hasExpired) {
        return (
            <div className={styles.container}>
                <div className='d-flex align-items-center flex-column'>
                    <ContentHeader
                        type='large-centered'
                        title={t('auth:mfa.pushAuth.expiredTitle')}
                        startSubtitle={t('auth:mfa.pushAuth.expiredDescription')}
                        className='mb-32 mt-32'
                    />

                    <Button
                        label={t('auth:mfa.pushAuth.resendNotification')}
                        skin='link'
                        className='mb-40'
                        onClick={retryPushAuthHandler}
                        isLoading={isInitiating}
                        isDisabled={isInitiating}
                    />
                    
                    {footerActions}
                </div>
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <div className='d-flex align-items-center flex-column'>
                <ContentHeader
                    type='large-centered'
                    title={t('auth:mfa.pushAuth.title')}
                    startSubtitle={t('auth:mfa.pushAuth.description')}
                    className='mb-32 mt-32'
                />

                <div className='mb-40'>
                    <CountdownTimer
                        duration={durationInMiliseconds}
                        isRunning
                        onTimeout={onTimeoutHandler}
                    />
                </div>
                
                {footerActions}
            </div>
        </div>
    );
};

PushAuthVerify.displayName = 'PushAuthVerify';
