import { CognitoIdToken, CognitoUser } from 'amazon-cognito-identity-js';
import { AxiosError } from 'axios';
import { route } from 'preact-router';
import { TFunction } from 'react-i18next';
import { CONFIG } from '../config/Config';
import { IAuthService, IMessageService } from '../services';
import { isEmpty } from '../utils/ArgumentValidations';
import { getPlatform, getSearch, Platform } from '../utils/Browser';
import { isRequestErrorInstance } from '../utils/Error';
import {
    AuthActionType,
    CognitoErrorCode,
    IAuthActions,
    IAuthStateAction,
    IResetPasswordError,
    RegistrationErrorCode,
    IAuthParams,
    AuthErrorType,
} from './types';
import { getUnexpectedError, parseAuthParams } from './utils';
import * as MultiFactorAuth from './multi-factor-auth';

const PASSWORD_POLICY_ERROR = 'Password does not conform to policy: ';
const EXPECTED_LINKING_ERROR = 'PreSignUp failed with error User is linked. Sign up is not performed. Please try to sign in again.';
const EXPECTED_PERSONAL_MAIL_ERROR = 'PreSignUp failed with error Personal emails are not allowed. Please use your company email.';
const EXPECTED_USER_NOT_FOUND = 'PreSignUp failed with error No user found for external identity.';

enum SecurityDetailsError {
    UNEXPECTED_ERROR = 'common:unexpectedError',
    WRONG_SECURITY_CODE = 'common:wrongSecurityCode',
    USER_NOT_AUTHENTICATED = 'common:userNotAuthenticated'
}

export class AuthActions implements IAuthActions {
    constructor(
        private readonly multiFactorAuthActions: MultiFactorAuth.IMultiFactorAuthActions,
        private readonly authService: IAuthService,
        private readonly messageService: IMessageService,
        private readonly dispatch: (a: IAuthStateAction) => void,
        private readonly t: TFunction<'common'[]>) {
    }

    changeHeight(height?: number) {
        this.messageService.changeHeight(height);
    }

    async checkPassword(password: string): Promise<boolean> {
        const userSession = await this.messageService.getUserSession();
        if (userSession) {
            this.dispatch({
                type: AuthActionType.PASSWORD_CHECK_LOADING,
            });
            try {
                await this.authService.checkPassword(password, userSession);
                this.messageService.enterCodeModal();
                this.dispatch({
                    type: AuthActionType.PASSWORD_CHECK_SUCCESS,
                });
            } catch (e) {
                this.messageService.enterCodeModal();
                this.dispatch({
                    type: AuthActionType.PASSWORD_CHECK_FAIL,
                });
            }

        }

        return false;
    }

    async showPasswordCheck(): Promise<void> {
        const session = await this.messageService.getUserSession();
        if (!session) {
            this.dispatch({
                type: AuthActionType.PASSWORD_CHECK_FAIL,
                data: this.t(SecurityDetailsError.USER_NOT_AUTHENTICATED),
            });

            return;
        }
        const idToken = new CognitoIdToken({ IdToken: session.idToken });
        const isSso = !!idToken.decodePayload().identities;

        if (!isSso) {
            this.dispatch({
                type: AuthActionType.SHOW_PASSWORD_CHECK,
            });
            this.messageService.passwordModal();
        } else {
            this.dispatch({
                type: AuthActionType.PASSWORD_CHECK_SUCCESS,
            });
            this.messageService.enterCodeModal();
        }
    }

    public setEmail(username: string) {
        this.dispatch({
            type: AuthActionType.SET_USERNAME,
            data: username,
        });
    }

    public async signIn(email: string, password: string) {
        this.dispatch({
            type: AuthActionType.SIGN_IN,
        });

        let restrictedUser: CognitoUser | undefined;

        try {
            restrictedUser = await this.authService.signIn(email, password);
        } catch (e) {
            let error = '';
            if (isRequestErrorInstance(e)) {
                switch (e.code) {
                    case CognitoErrorCode.UserNotFoundException:
                    case CognitoErrorCode.NotAuthorizedException:
                        error = this.t('common:incorrectEmailOrPassword');
                        break;
                    case CognitoErrorCode.PasswordResetRequiredException:
                        error = this.t('common:resetPasswordRequired');
                        break;
                    case CognitoErrorCode.TooManyRequestsException:
                        error = e.message || '';
                        break;
                    case CognitoErrorCode.UserNotConfirmedException:
                        route('/signup-confirm');
                        break;
                    case CognitoErrorCode.NewPasswordRequiredException:
                        this.dispatch({
                            type: AuthActionType.CONFIRM_SIGN_UP,
                            data: {
                                password,
                                isLoginWithTempPassword: true
                            }
                        });
                        route('/sign-up');
                        break;
                    default:
                        error = getUnexpectedError(this.t);
                        break;
                }
            }

            this.dispatch({
                type: AuthActionType.SIGN_IN_FAIL,
                data: error,
            });

            return;
        }

        await this.multiFactorAuthActions.onRestrictedSessionCreated(restrictedUser);

        this.dispatch({
            type: AuthActionType.SIGN_IN_SUCCESS,
        });
    }

    public async checkEmail(email: string) {
        this.dispatch({
            type: AuthActionType.CHECK_EMAIL,
        });

        let identityProvider: string | undefined;
        try {
            // Actual request to find out identity provider should go here
            const result = await this.authService.findIdentityProvider(email);
            if (result.hasExternalProvider) {
                identityProvider = result.name;
            }
        } catch {
            this.dispatch({
                type: AuthActionType.CHECK_EMAIL_FAIL,
                data: this.t('common:unexpectedErrorOccurWhileCheckingEmail'),
            });
        }

        if (identityProvider) {
            this.externalProviderSignIn(identityProvider);
        } else {
            route('/password');
        }

        this.dispatch({
            type: AuthActionType.CHECK_EMAIL_SUCCESS,
        });
    }

    public async signUp(email: string, onboardingFlow?: string, opportunityId?: string) {
        if (isEmpty(email)) {
            this.dispatch({
                type: AuthActionType.SIGN_UP_FAIL,
                data: this.t('common:emailRequired'),
            });

            return;
        }

        this.dispatch({
            type: AuthActionType.SIGN_UP,
        });

        try {
            const userId = await this.authService.signUp(email, onboardingFlow, opportunityId);

            const providerResult = await this.authService.findIdentityProvider(email);
            if (providerResult.hasExternalProvider) {
                this.externalProviderSignIn(providerResult.name);

                return;
            }

            this.dispatch({
                type: AuthActionType.SIGN_UP_SUCCESS,
                data: userId,
            });
        } catch (e) {
            let message = getUnexpectedError(this.t);
            const err = e as AxiosError;
            if (err.isAxiosError &&
                err.response &&
                err.response.status === 400 &&
                err.response.data &&
                err.response.data.message) {
                message = err.response.data.message;
            }

            this.dispatch({
                type: AuthActionType.SIGN_UP_FAIL,
                data: message,
            });
        }
    }

    public async resendVerificationCode(email: string, onboardingFlow?: string) {
        this.dispatch({
            type: AuthActionType.RESEND_CONFIRMATION_CODE
        });

        try {
            await this.authService.resendVerificationCode(email, onboardingFlow);
        } catch (e) {
            this.dispatch({
                type: AuthActionType.RESEND_CONFIRMATION_CODE_FAIL,
                data: getUnexpectedError(this.t),
            });
        }

        this.dispatch({
            type: AuthActionType.RESEND_CONFIRMATION_CODE_SUCCESS,
        });
    }

    public async verifySignUp(userId: string, verificationCode: string) {
        if (isEmpty(verificationCode)) {
            this.dispatch({
                type: AuthActionType.VERIFY_SIGN_UP_FAIL,
                data: this.t('common:verificationCodeRequired'),
            });

            return;
        }

        this.dispatch({
            type: AuthActionType.VERIFY_SIGN_UP,
        });

        try {
            const result = await this.authService.verifySignUp(userId, verificationCode);

            this.dispatch({
                type: AuthActionType.VERIFY_SIGN_UP_SUCCESS,
                data: result,
            });
        } catch (e) {
            let message = getUnexpectedError(this.t);
            const err = e as AxiosError;

            if (err.isAxiosError && err.response?.status === 400 && err.response.data?.message) {
                message = err.response.data.message;
            }

            this.dispatch({
                type: AuthActionType.VERIFY_SIGN_UP_FAIL,
                data: message,
            });
        }
    }

    public async verifyInvitation(userId: string, verificationCode: string, email?: string) {
        if (isEmpty(userId) || isEmpty(verificationCode)) {
            return;
        }

        this.dispatch({
            type: AuthActionType.VERIFY_INVITATION,
        });

        try {
            const result = await this.authService.verifySignUp(userId, verificationCode);
            const providerResult = await this.authService.findIdentityProvider(result.email);
            if (providerResult.hasExternalProvider) {
                this.externalProviderSignIn(providerResult.name);
            } else {
                this.dispatch({
                    type: AuthActionType.VERIFY_INVITATION_SUCCESS,
                    data: { ...result, userId },
                });
            }
        } catch (e) {
            if (email) {
                try {
                    const providerResult = await this.authService.findIdentityProvider(email);
                    if (providerResult.hasExternalProvider) {
                        this.externalProviderSignIn(providerResult.name);

                        return;
                    }
                } catch {
                    // Most users don't have SSO. If we fail here, the flow should continue in order not to disrupt most common experience.
                    console.error('Skipped due to failure with {error}', e);
                }
            }

            const err = e as AxiosError;

            let message = getUnexpectedError(this.t);
            let errorToJson;
            let type;

            try {
                errorToJson = (err as any)?.toJSON();
            } catch (je) {
                console.log('Could not cast the error object to JSON');
            }

            // TODO: remove this condition after changes to CARDS_API_URL and API_URL domains are deployed on production
            if (err?.response?.status === 403 || errorToJson?.message?.toLowerCase() === 'network error') {
                message = this.t('common:networkErrorMessage');
                type = RegistrationErrorCode.NetworkError;
            } else if (err.isAxiosError && err.response?.status === 400 && err.response.data?.message) {
                message = err.response.data.message;
            }

            this.dispatch({
                type: AuthActionType.VERIFY_INVITATION_FAIL,
                data: {
                    type,
                    message,
                },
            });
        }
    }

    public async confirmSignUp(email: string, userId: string, tempPassword: string, newPassword: string, newPasswordConfirm: string, verificationCode: string, isLoginWithTempPassword?: boolean) {
        if (isEmpty(email)) {
            this.dispatch({
                type: AuthActionType.CONFIRM_SIGN_UP_FAIL,
                data: this.t('common:emailRequired'),
            });

            return;
        }

        if (isEmpty(verificationCode) && !isLoginWithTempPassword) {
            this.dispatch({
                type: AuthActionType.CONFIRM_SIGN_UP_FAIL,
                data: this.t('common:confirmationCodeRequired'),
            });

            return;
        }

        if (isEmpty(newPassword)) {
            this.dispatch({
                type: AuthActionType.CONFIRM_SIGN_UP_FAIL,
                data: this.t('common:passwordRequired'),
            });

            return;
        }

        if (isEmpty(newPasswordConfirm)) {
            this.dispatch({
                type: AuthActionType.CONFIRM_SIGN_UP_FAIL,
                data: this.t('common:confirmPasswordRequired'),
            });

            return;
        }

        if (newPassword !== newPasswordConfirm) {
            this.dispatch({
                type: AuthActionType.CONFIRM_SIGN_UP_FAIL,
                data: this.t('common:passwordsShouldMatch'),
            });

            return;
        }
        // fix for the jumping back and forth with the mail screen
        const stateAction = { 
            type: AuthActionType.CONFIRM_SIGN_UP, 
            data: {
                password:tempPassword,
                isLoginWithTempPassword: true,
            },
        } as IAuthStateAction;

        this.dispatch(stateAction);
        
        try {
            const restrictedAccessToken = await this.authService.signInWithTempPassword(email, tempPassword, newPassword);

            if (!isLoginWithTempPassword) {
                await this.authService.confirmSignUp(userId, verificationCode);
            }

            await this.multiFactorAuthActions.onRestrictedSessionCreated(restrictedAccessToken);

            this.dispatch({
                type: AuthActionType.CONFIRM_SIGN_UP_SUCCESS
            });
        } catch (e) {
            let message = getUnexpectedError(this.t);
            if (isRequestErrorInstance(e)) {
                switch (e.code) {
                    case CognitoErrorCode.InvalidParameterException:
                    case CognitoErrorCode.InvalidPasswordException:
                    case CognitoErrorCode.TooManyRequestsException:
                    case CognitoErrorCode.UsernameExistsException:
                        message = e.message || getUnexpectedError(this.t);

                        if (message.startsWith(PASSWORD_POLICY_ERROR)) {
                            message = message.substr(PASSWORD_POLICY_ERROR.length);
                        }

                        break;
                    case CognitoErrorCode.NotAuthorizedException:
                        message = this.t('common:expiredInvitationMessage');

                        if (e?.message?.toLowerCase() !== 'temporary password has expired and must be reset by an administrator.') {
                            break;
                        }

                        this.dispatch({
                            type: AuthActionType.VERIFY_INVITATION_FAIL,
                            data: {
                                message,
                                type: RegistrationErrorCode.TempPasswordExpired,
                            }
                        });

                        break;
                    default:
                        const err = e as AxiosError;
                        if (err.isAxiosError &&
                            err.response &&
                            err.response.status === 400 &&
                            err.response.data &&
                            err.response.data.message) {
                            message = err.response.data.message;
                        }
                        break;
                }
            }

            this.dispatch({
                type: AuthActionType.CONFIRM_SIGN_UP_FAIL,
                data: message,
            });

        }
    }

    public cleanSignUp() {
        this.dispatch({
            type: AuthActionType.CLEAN_SIGN_UP,
        });
    }

    public async resetPassword(email: string): Promise<void> {
        this.dispatch({
            type: AuthActionType.RESET_PASSWORD,
        });

        try {
            await this.authService.resetPassword(email);
        } catch (e) {
            let error = '';
            if (isRequestErrorInstance(e)) {
                switch (e.code) {
                    case CognitoErrorCode.LimitExceededException:
                    case CognitoErrorCode.TooManyRequestsException:
                    case CognitoErrorCode.NotAuthorizedException:
                        error = e.message || '';
                        break;
                    case CognitoErrorCode.UserNotFoundException:
                        // Skip this error and continue to /reset-password-confirm
                        break;
                    default:
                        error = getUnexpectedError(this.t);
                        break;
                }
            } else if (e instanceof Error) {
                if (e.message === 'notRegistered') {
                    // if the user has not yet completed the registration we can resend the invitation link
                    await this.authService.resendInvitationLink(email);

                    route('/reset-password-pending-registration');
                } else if (e.message === 'hasSingleSignOn') {
                    error = this.t('common:pleaseUseSingleSignOn');
                } else if (e.message === 'shouldLoginWithTempPass') {
                    error = this.t('common:pleaseUseGivenPasswordByAdmin');
                }
            }

            this.dispatch({
                type: AuthActionType.RESET_PASSWORD_FAIL,
                data: error,
            });

            return;
        }

        this.dispatch({
            type: AuthActionType.RESET_PASSWORD_SUCCESS,
        });

        route('/reset-password-confirm');
    }

    public async resetPasswordConfirm(email: string, newPassword: string, verificationCode: string) {
        this.dispatch({
            type: AuthActionType.RESET_PASSWORD,
        });

        try {
            await this.authService.resetPasswordConfirm(email, newPassword, verificationCode);
        } catch (e) {
            let errorState: IResetPasswordError = {};

            if (isRequestErrorInstance(e)) {
                const errorMessage = e.message || '';

                switch (e.code) {
                    case CognitoErrorCode.CodeMismatchException:
                    case CognitoErrorCode.ExpiredCodeException:
                        errorState = {
                            verificationCodeError: errorMessage
                        };
                        break;
                    case CognitoErrorCode.LimitExceededException:
                    case CognitoErrorCode.TooManyRequestsException:
                    case CognitoErrorCode.InvalidParameterException:
                        errorState = {
                            newPasswordError: errorMessage,
                            verificationCodeError: errorMessage
                        };
                        break;
                    case CognitoErrorCode.InvalidPasswordException:
                        errorState = {
                            newPasswordError: errorMessage
                        };
                        break;
                    case CognitoErrorCode.UserNotConfirmedException:
                        route('/signup-confirm');
                        break;
                    default:
                        const unexpectedErrorMessage = getUnexpectedError(this.t);

                        errorState = {
                            newPasswordError: unexpectedErrorMessage,
                            verificationCodeError: unexpectedErrorMessage
                        };
                        break;
                }
            }
            this.dispatch({
                type: AuthActionType.RESET_PASSWORD_FAIL,
                data: errorState,
            });

            return;
        }

        try {
            const restrictedAccessToken = await this.authService.signIn(email, newPassword);
            await this.multiFactorAuthActions.onRestrictedSessionCreated(restrictedAccessToken);
        } catch (e) {
            route('/');
        }

        this.dispatch({
            type: AuthActionType.RESET_PASSWORD_SUCCESS,
        });
    }

    public contactSupport() {
        this.messageService.contactSupport();
    }

    public cardDetailsOpened() {
        this.messageService.cardDetailsOpened();
    }

    public cancelSecurityCodeChange() {
        this.messageService.securityCodeChangeCanceled();
    }

    public securityCodeChangedSuccessfully() {
        this.messageService.securityCodeChangedSuccessfully();
    }

    public async verifySecurityCode(code: string, isTemp: boolean): Promise<void> {
        this.dispatch({ type: AuthActionType.VERIFY_SECURITY_CODE });

        const session = await this.messageService.getUserSession();

        if (!session) {
            this.dispatch({
                type: AuthActionType.VERIFY_SECURITY_CODE_FAIL,
                data: this.t(SecurityDetailsError.USER_NOT_AUTHENTICATED),
            });

            return;
        }

        try {
            const { isValid } = await this.authService.verifySecurityCode(code, session, isTemp);
            if (!isValid) {
                this.dispatch({
                    type: AuthActionType.VERIFY_SECURITY_CODE_FAIL,
                    data: SecurityDetailsError.WRONG_SECURITY_CODE,
                });

                return;
            }

            this.dispatch({
                type: AuthActionType.VERIFY_SECURITY_CODE_SUCCESS,
                data: { isValid },
            });
        } catch (e) {
            this.dispatch({
                type: AuthActionType.VERIFY_SECURITY_CODE_FAIL,
                data: this.t(SecurityDetailsError.UNEXPECTED_ERROR),
            });

            return;
        }
    }

    public async setSecurityCode(newCode: string, oldCode: string | null, isTempCode: boolean = false): Promise<void> {
        this.dispatch({ type: AuthActionType.SET_SECURITY_CODE });

        const session = await this.messageService.getUserSession();

        if (!session) {
            this.dispatch({
                type: AuthActionType.SET_SECURITY_CODE_FAIL,
                data: this.t(SecurityDetailsError.USER_NOT_AUTHENTICATED),
            });

            return;
        }

        try {
            await this.authService.setSecurityCode(newCode, oldCode, session, isTempCode);
            this.dispatch({ type: AuthActionType.SET_SECURITY_CODE_SUCCESS, data: newCode });
            this.messageService.securityCodeChangedSuccessfully();
        } catch (e) {
            this.dispatch({
                type: AuthActionType.SET_SECURITY_CODE_FAIL,
                data: this.t(SecurityDetailsError.UNEXPECTED_ERROR),
            });

            return;
        }
    }

    public async hasSecurityCode(): Promise<void> {
        this.dispatch({ type: AuthActionType.GET_SECURITY_CODE });
        const session = await this.messageService.getUserSession();

        if (!session) {
            this.dispatch({
                type: AuthActionType.GET_SECURITY_CODE_FAIL,
                data: this.t(SecurityDetailsError.USER_NOT_AUTHENTICATED),
            });

            return;
        }
        try {
            const hasSecurityCode = await this.authService.hasSecurityCode(session);
            this.dispatch({
                type: AuthActionType.GET_SECURITY_CODE_SUCCESS,
                data: hasSecurityCode,
            });
            if (!hasSecurityCode) {
                this.messageService.noSecurityCodeModal();
            }
        } catch (e) {
            this.dispatch({
                type: AuthActionType.GET_SECURITY_CODE_FAIL,
                data: this.t(SecurityDetailsError.UNEXPECTED_ERROR),
            });

            return;
        }
    }

    public async getCardDetails(token: string, code: string): Promise<void> {
        this.dispatch({ type: AuthActionType.GET_CARD_DETAILS });
        const session = await this.messageService.getUserSession();

        if (!session) {
            this.dispatch({
                type: AuthActionType.GET_CARD_DETAILS_FAIL,
                data: this.t(SecurityDetailsError.USER_NOT_AUTHENTICATED),
            });

            return;
        }

        try {
            const { number: cardNumber, ...otherDetails } = await this.authService.getCardDetails(token, code, session);

            this.dispatch({
                type: AuthActionType.GET_CARD_DETAILS_SUCCESS,
                data: {
                    ...otherDetails,
                    cardNumber
                }
            });
        } catch (e: any) {
            const error = e?.response?.status === 401 ?
                SecurityDetailsError.WRONG_SECURITY_CODE :
                SecurityDetailsError.UNEXPECTED_ERROR;

            if (e?.response?.data?.remainingRetryCount) {
                this.dispatch({
                    type: AuthActionType.GET_CARD_DETAILS_FAIL_REMAINING_RETRY_COUNT,
                    data: e.response.data.remainingRetryCount,
                });

                return;
            }

            if (e?.response?.status === 400) {
                this.dispatch({
                    type: AuthActionType.GET_CARD_DETAILS_FAIL_MAXIMUM_RETRIES_EXCEEDED
                });

                return;
            }

            this.dispatch({
                type: AuthActionType.GET_CARD_DETAILS_FAIL,
                data: this.t(error),
            });
        }
    }

    public async changeTitleToSecurityCodeDisabled(): Promise<void> {
        try {
            const session = await this.messageService.getUserSession();
            if (!session) {
                this.dispatch({
                    type: AuthActionType.RESET_SECURITY_CODE_FAIL,
                    data: this.t(SecurityDetailsError.USER_NOT_AUTHENTICATED),
                });

                return;
            }
            await this.messageService.securityCodeDisabled();
        }
        catch (err) {
            this.dispatch({
                type: AuthActionType.RESET_SECURITY_CODE_FAIL,
                data: this.t(SecurityDetailsError.UNEXPECTED_ERROR),
            });
        }
    }

    public async resetSecurityCode(): Promise<void> {
        this.dispatch({ type: AuthActionType.RESET_SECURITY_CODE });

        try {
            const session = await this.messageService.getUserSession();
            if (!session) {
                this.dispatch({
                    type: AuthActionType.RESET_SECURITY_CODE_FAIL,
                    data: this.t(SecurityDetailsError.USER_NOT_AUTHENTICATED),
                });

                return;
            }

            await this.authService.resetSecurityCode(session);

            this.dispatch({ type: AuthActionType.RESET_SECURITY_CODE_SUCCESS });
            this.messageService.resetSecurityCode();
        } catch (err) {
            this.dispatch({
                type: AuthActionType.RESET_SECURITY_CODE_FAIL,
                data: this.t(SecurityDetailsError.UNEXPECTED_ERROR),
            });
        }
    }

    public async externalProviderSignIn(provider: string) {
        const platform: Platform = getPlatform();
        const redirectURL = getRedirectUrlForExternalAuthentication(platform);
        const clientId = CONFIG.AUTHENTICATION.clientId[platform].restricted;

        let url = `${CONFIG.AUTHENTICATION.ssoEndpoint}oauth2/authorize?identity_provider=${encodeURIComponent(provider)}&redirect_uri=${encodeURIComponent(redirectURL)}&response_type=CODE&client_id=${encodeURIComponent(clientId)}&scope=aws.cognito.signin.user.admin%20openid`;
        if (platform === Platform.wallet) {
            url += `&state=${encodeURIComponent(getStateForExternalAuthentication())}`;
        }

        window.parent.location.href = url;
    }

    /**
     * Processes the authentication parameters.
     * @param {string} baseSearch Search query from the base location
     * @param {string} search Search query from the app location
     * @returns {void} Nothing
     */
    public async processAuthenticationParams(baseSearch: string, search: string): Promise<void> {
        const baseSearchParams = new URLSearchParams(baseSearch);
        if (baseSearchParams.has('state')) {
            const state = baseSearchParams.get('state')!;
            baseSearchParams.delete('state');

            const stateParams = new URLSearchParams(state);
            stateParams.forEach((value, key) => baseSearchParams.set(key, value));

            window.location.href = `${CONFIG.URL}?${baseSearchParams.toString()}#/${search}`;

            return;
        }

        const params = parseAuthParams(baseSearch, search);

        if (params.code) {
            await this.signInWithCode(params.code);

            return;
        }

        if (params.access_token && params.id_token) {
            await this.signInWithTokens(params.access_token, params.id_token);

            return;
        }

        if (params.error_description) {
            // This is a hack to amend Cognito issue of linking users on preSignUp
            const linkingErrorIndex = params.error_description.indexOf(EXPECTED_LINKING_ERROR);
            const personalEmailErrorIndex = params.error_description.indexOf(EXPECTED_PERSONAL_MAIL_ERROR);
            const userNotFountErrorIndex = params.error_description.indexOf(EXPECTED_USER_NOT_FOUND);
            if (linkingErrorIndex >= 0) {
                const username = params.error_description.substring(linkingErrorIndex + EXPECTED_LINKING_ERROR.length + 1, params.error_description.length);

                const underscoreIdx = username.indexOf('_');

                // We find out what was the name of the external provider and direct the user to authenticate again
                if (underscoreIdx > -1) {
                    let providerName = username.substring(0, underscoreIdx);

                    if (providerName === 'google') {
                        providerName = 'Google';
                    }

                    await this.externalProviderSignIn(providerName);
                }
            } else if (personalEmailErrorIndex >= 0) {
                this.dispatch({
                    type: AuthActionType.CHECK_EMAIL_FAIL,
                    data: 'Please use your company email.',
                });
            } else if (userNotFountErrorIndex >= 0) {
                this.dispatch({
                    type: AuthActionType.EXTERNAL_SIGN_IN_FAIL,
                    data: AuthErrorType.RegistrationErrorNoSuchUser,
                });
            } else {
                this.messageService.userSignInFailed(params.error_description);
                // this.dispatch({
                //     type: AuthActionType.EXTERNAL_SIGN_IN_FAIL,
                // });
            }

            return;
        }

        if (params.auth_error) {
            this.dispatch({
                type: AuthActionType.CHECK_EMAIL_FAIL,
                data: params.auth_error,
            });

            return;
        }

        if (params.errorCode) {
            this.dispatch({
                type: AuthActionType.EXTERNAL_SIGN_IN_FAIL,
                data: AuthErrorType.RegistrationErrorNoSuchUser,
            });

            return;
        }
    }

    private async signInWithCode(code: string) {
        this.dispatch({
            type: AuthActionType.EXTERNAL_SIGN_IN,
        });

        try {
            const platform: Platform = getPlatform();
            const redirectURL = getRedirectUrlForExternalAuthentication(platform);
            const clientId = CONFIG.AUTHENTICATION.clientId[platform].restricted;

            const restrictedUser = await this.authService.signInWithCode(code, clientId, redirectURL);
            await this.multiFactorAuthActions.onRestrictedSessionCreated(restrictedUser);
        } catch (e: any) {
            if (e?.message === 'Failed to exchange the authentication code for tokens because the user does not exist') {
                this.dispatch({
                    type: AuthActionType.EXTERNAL_SIGN_IN_FAIL,
                    data: AuthErrorType.RegistrationErrorNoSuchUser,
                });
            } else {
                this.messageService.userSignInFailed(e.message || e);
                this.dispatch({
                    type: AuthActionType.EXTERNAL_SIGN_IN_FAIL,
                });
            }

            return;
        }

        this.dispatch({
            type: AuthActionType.EXTERNAL_SIGN_IN_SUCCESS,
        });
    }

    private async signInWithTokens(accessToken: string, idToken: string) {
        this.messageService.userSignedIn({
            accessToken,
            idToken,
            refreshToken: '',
        });
    }
}

function getRedirectUrlForExternalAuthentication(platform: Platform) {
    switch (platform) {
        case Platform.mobile:
            return 'payhawk://oauth/';
        case Platform.wallet:
            return CONFIG.URL;
        case Platform.web:
            return CONFIG.PORTAL_URL;
    }
}

function getStateForExternalAuthentication(): string {
    const search = getSearch();
    const searchParams = new URLSearchParams(search);
    const stateParams = new URLSearchParams();

    const allowedStateParams = ['version', 'lng', 'platform'];

    allowedStateParams.forEach(allowedParamName => {
        const paramValue = searchParams.get(allowedParamName);
        if (paramValue) {
            stateParams.set(allowedParamName, paramValue);
        }
    });

    return stateParams.toString();
}
