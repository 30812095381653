import { h } from 'preact';
import { useTranslation } from 'react-i18next';

import { RegistrationErrorCode, useAuth } from '../../auth';
import styles from './Error.module.scss';

const Error: preact.FunctionalComponent = ({ status }: any) => {
    const [authState, authActions] = useAuth();
    const { t } = useTranslation(['common']);
    
    const errorType = authState.registrationError;
    const message = authState.signUpError;

    const header = errorType === RegistrationErrorCode.NetworkError ? t('common:networkError') : 
        errorType === RegistrationErrorCode.TempPasswordExpired ? t('common:registrationLinkExpired') : t('common:unexpectedError');

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.title}>
                    {header}
                </div>
                <div className={styles.description}>
                    {message}
                </div>
            </div>
        </div>        
    );
};

export default Error;
