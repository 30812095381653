import { useEffect, useRef } from 'preact/hooks';

export const useAutoFocus = () => {
    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        inputRef.current?.focus();
    }, [inputRef.current]);

    return inputRef;
};
