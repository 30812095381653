import { h } from 'preact';

import { useEffect, useState } from 'preact/hooks';
import { useAuth } from '../../../auth';

import { CompleteUserProfile } from './CompleteUserProfile';
import { CompletePhoneVerification } from './CompletePhoneVerification';
import { IUserInfo } from '../../../services';
import { LoadingScreen } from '../../../components';

export const UserProfileSetup = () => {
    const [ authState, _, multiFactorAuthActions ] = useAuth();
    const { isInitiating, restrictedSession, challenge } = authState.multiFactorAuth;
    const [ isPhoneVerificationOpen, setIsPhoneVerificationOpen ] = useState(false);
    const [ userInfo, setUserInfo ] = useState<IUserInfo>();

    const hasUserProfileSetupChallenge = !!(restrictedSession && !isInitiating && challenge?.data.type === 'PAYHAWK_USER_PROFILE_SETUP');

    useEffect(() => {
        if (!hasUserProfileSetupChallenge) {
            return;
        }

        multiFactorAuthActions.getUserInfo(restrictedSession.user)
            .then(data => {
                setUserInfo(data);
                if (data?.phoneNumberVerified) {
                    setIsPhoneVerificationOpen(true);
                }
            });
    }, [hasUserProfileSetupChallenge, restrictedSession?.user]);

    const onUserUpdatedHandler = async (updatedUserInfo: IUserInfo) => {
        setUserInfo(updatedUserInfo);

        if (!challenge || !restrictedSession) {
            return;
        }

        if (updatedUserInfo.phoneNumberVerified) {
            await multiFactorAuthActions.resolveChallenge(challenge, restrictedSession);
        } else {
            setIsPhoneVerificationOpen(true);
        }
    };

    const onChangePhoneNumberHandler = () => {
        setIsPhoneVerificationOpen(false);
    };

    if (!hasUserProfileSetupChallenge) {
        return null;
    }

    if (!userInfo) {
        return <LoadingScreen />;
    }

    return isPhoneVerificationOpen ? 
        <CompletePhoneVerification userInfo={userInfo} onChangePhoneNumber={onChangePhoneNumberHandler} /> :
        <CompleteUserProfile userInfo={userInfo} onUserUpdated={onUserUpdatedHandler} />;
        
};

UserProfileSetup.displayName = 'UserProfileSetup';
