import { h } from 'preact';
import { useCallback, useEffect, useState } from 'preact/hooks';
import { useTranslation } from 'react-i18next';
import { Spinner } from '@payhawk/hawky-react';
import { useAuth } from '../../auth';
import styles from './ChangeSecurityCode.module.scss';
import SecurityCode from '../../components/SecurityCode/SecurityCode';

import { Loading } from '../card-details/VerifySecurityCode';
import { useUpdateHeight } from '../../utils/useUpdateHeight';

const ChangeSecurityCode = () => {
    const [authState, authActions] = useAuth();
    const { t } = useTranslation(['common']);
    const [oldCode, setOldCode] = useState<string | null>(null);
    const [newCode, setNewCode] = useState('');
    const [verifyCodeError, setVerifyCodeError] = useState('');
    const [confirmError, setConfirmError] = useState('');
    const { hasSecurityCode } = authState;
    const [isCodeVerified, setIsCodeVerified] = useState(false);

    const onChange = useCallback(() => {
        setVerifyCodeError('');
        setConfirmError('');
    }, [setVerifyCodeError, setConfirmError]);

    const onStartOver = useCallback(() => {
        setNewCode('');
        setVerifyCodeError('');
        setConfirmError('');
    }, [setNewCode, setVerifyCodeError, setConfirmError]);

    const onForgotCodeClicked = useCallback(() => {
        authActions.resetSecurityCode();
    }, [authActions]);

    const onSubmitCurrentSecurityCode = useCallback((input: string) => {
        authActions.verifySecurityCode(input, false);
        setOldCode(input);
    }, [authActions, setOldCode]);

    const onSubmitNewSecurityCode = useCallback((input: string) => {
        setNewCode(input);
    }, [authActions, setNewCode]);

    const onConfirmNewSecurityCode = useCallback((input: string) => {
        if (input !== newCode) {
            setConfirmError('incorrectCodeConfirmation');

            return;
        }
        authActions.setSecurityCode(newCode, oldCode);

    }, [authActions, setConfirmError, oldCode, newCode]);

    useEffect(() => {
        if (authState.verifyCodeError) {
            setVerifyCodeError(authState.verifyCodeError);
        }

        if (authState.setNewCodeError) {
            setConfirmError(authState.setNewCodeError);
        }

        if (authState.isCodeValid) {
            setIsCodeVerified(authState.isCodeValid);
        }
    }, [setConfirmError, setVerifyCodeError, setIsCodeVerified, authState]);

    useEffect(() => {
        if (authState.hasSecurityCode !== undefined){
            return;
        }
        void authActions.hasSecurityCode();
    }, []);

    useUpdateHeight();
    
    const isSendingRequest = authState.isSendingResetSecurityCode || authState.isVerifyingSecurityCode || authState.isSendingNewSecurityCode;

    if(authState.isGettingSecurityCode){
        return <div class={styles.container}>
            <Loading />
        </div>;
    }

    return (
        <div class={styles.container}>
            {
                isSendingRequest ?
                    <div class={styles.spinner}>
                        <Spinner size='large' />
                    </div> :
                    !isCodeVerified && hasSecurityCode ?
                        <SecurityCode
                            key='enter-current-code'
                            title={t('common:enterCurrentSecurityCode')}
                            buttonText={t('common:continue')}
                            onConfirm={onSubmitCurrentSecurityCode}
                            onChange={onChange}
                            errorMessageKey={verifyCodeError}
                            footerText={t('common:forgotCode')}
                            onFooterClick={onForgotCodeClicked}
                        /> :
                        <SecurityCode
                            key={confirmError ? 'verify-code-error' : (newCode ? 'set-new-code' : 'verify-new-code')}
                            title={newCode ? t('common:confirmNewSecurityCode') : (!hasSecurityCode ? t('common:setASecurityCode') : t('common:setNewSecurityCode'))}
                            subtitle={!hasSecurityCode ? <div>{t('common:setSecurityCodeSubtitle')}</div> : undefined}
                            buttonText={newCode ? t('common:confirm') : t('common:continue')}
                            onConfirm={newCode ? onConfirmNewSecurityCode : onSubmitNewSecurityCode}
                            onChange={onChange}
                            errorMessageKey={confirmError}
                            errorAction={onStartOver}
                        />
            }
        </div>
    );
};

export default ChangeSecurityCode;
