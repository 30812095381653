import { IAuthParams } from './types';
import { TFunction } from 'react-i18next';

export function parseAuthParams(baseSearch: string, search: string): IAuthParams {
    const baseParams = new URLSearchParams(baseSearch);
    const params = new URLSearchParams(search);

    const getParam = (key: string) => baseParams.get(key) || params.get(key) || undefined;

    const authParams: IAuthParams = {
        code: getParam('code'),
        access_token: getParam('access_token'),
        id_token: getParam('id_token'),
        error_description: getParam('error_description'),
        errorCode: getParam('errorCode'),
        auth_error: getParam('auth_error'),
    };

    return authParams;
}

export function getUnexpectedError(t: TFunction<'common'[]>) {
    return t('common:unexpectedError');
}