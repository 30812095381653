export interface IRequestError {
    code?: string;
    message?: string;
}

export function isRequestErrorInstance(error: unknown): error is IRequestError {
    const castError = (error as IRequestError);
    if (castError.code !== undefined) {
        return true;
    }

    return false;
}