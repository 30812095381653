import { h } from 'preact';
import { useCallback } from 'preact/hooks';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../auth';
import { getSearch } from '../../utils/Browser';

import styles from './ResetPassword.module.scss';
import {
    Button,
    InputState,
    ITextInputOnChangeProps,
} from '@payhawk/hawky-react';
import { TextInputAutoFocus } from '../../utils/TextInputAutoFocus';

const ResetPassword: preact.FunctionalComponent = () => {
    const [authState, authActions] = useAuth();
    const { t } = useTranslation(['common']);

    const onInputChange = useCallback(({ name, value }: ITextInputOnChangeProps) => {
        switch (name) {
            case 'email':
                authActions.setEmail(value);
                break;
            default:
                break;
        }
    }, [authActions]);

    const onSubmit = useCallback(async (e: h.JSX.TargetedEvent) => {
        e.preventDefault();
        authActions.resetPassword(authState.email);
    }, [authState.email, authActions]);

    const resetPasswordError = authState?.resetPasswordError;
    const passwordErrorMessage = resetPasswordError?.newPasswordError || '';

    return (
        <div class={styles.container}>
            <div class={styles.title}>{t('common:resetPassword')}</div>
            <div class={styles.subtitle}>{t('common:provideYourEmail')}</div>
            <div class={styles.wrap}>
                <form onSubmit={onSubmit}>
                    <TextInputAutoFocus
                        label={t('common:email')}
                        id='email'
                        name='email'
                        type='email'
                        value={authState.email}
                        onChange={onInputChange}
                        isReadOnly={authState.isResettingPassword}
                        isRequired
                        state={passwordErrorMessage || resetPasswordError ? InputState.Error : InputState.None}
                        stateText={passwordErrorMessage ? passwordErrorMessage : (resetPasswordError ? resetPasswordError : '')}
                        placeholder={t('common:enterEmail')}
                    />
                    <Button
                        className='mt-32'
                        label={t('common:resetPassword')}
                        isExpanded
                        type='submit'
                        isDisabled={authState.isResettingPassword}
                        isLoading={authState.isResettingPassword}
                    />

                    <div class={styles.actions}>
                        <span>{t('common:rememberedMyPassword')}</span>
                        <a href={`/${getSearch()}`} class={styles.link}>{t('common:loginText')}</a>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ResetPassword;
