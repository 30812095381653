import { h } from 'preact';

import { useAuth } from '../../auth';
import { useEffect } from 'preact/hooks';
import { route } from 'preact-router';
import { LoadingScreen } from '../../components';
import { PushAuthVerify } from './PushAuthVerify';
import { SmsVerify } from './SmsVerify';
import { UserProfileSetup } from './user-profile-setup/UserProfileSetup';
import { PushAuthSetupRequired } from './PushAuthSetupRequired';

export const MultiFactorAuth = () => {
    const [ authState ] = useAuth();
    const { isInitiating, restrictedSession, challenge } = authState.multiFactorAuth;

    const hasChallenge = !!(restrictedSession && challenge);

    useEffect(() => {
        if (!isInitiating && !hasChallenge) {
            route('/');
        }
    }, [isInitiating, hasChallenge]);

    if (isInitiating && !hasChallenge) {
        return <LoadingScreen />;
    }

    switch(challenge?.data.type) {
        case 'PAYHAWK_PUSH':
            return <PushAuthVerify key={challenge.data.challengeId} />;
        case 'PAYHAWK_SMS':
            return <SmsVerify />;
        case 'PAYHAWK_USER_PROFILE_SETUP':
            return <UserProfileSetup />;
        case 'PAYHAWK_PUSH_SETUP':
            return <PushAuthSetupRequired />;
        default:
            return null;
    }
};

MultiFactorAuth.displayName = 'MultiFactorAuth';
