import { h } from 'preact';
import { useCallback, useEffect, useState } from 'preact/hooks';
import { Trans, useTranslation } from 'react-i18next';
import { Spinner } from '@payhawk/hawky-react';
import { getSearch } from '../../utils/Browser';
import { useAuth } from '../../auth';
import SecurityCode from '../../components/SecurityCode/SecurityCode';
import CodeResent from './CodeResent';
import styles from './ForgotSecurityCode.module.scss';

const search = new URLSearchParams(getSearch());
const email = search.get('email') || '';

const ForgotSecurityCode: preact.FunctionalComponent = () => {
    const [authState, authActions] = useAuth();
    const { t } = useTranslation(['common']);
    const [oldCode, setOldCode] = useState('');
    const [newCode, setNewCode] = useState('');
    const [verifyCodeError, setVerifyCodeError] = useState('');
    const [confirmError, setConfirmError] = useState('');
    const [isCodeVerified, setIsCodeVerified] = useState(false);

    const onChange = useCallback(() => {
        setVerifyCodeError('');
        setConfirmError('');
    }, [setVerifyCodeError, setConfirmError]);

    const onResendCodeClicked = useCallback(() => {
        authActions.resetSecurityCode();
    }, [authActions]);

    const onSubmitCurrentSecurityCode = useCallback((input: string) => {
        authActions.verifySecurityCode(input, true);
        setOldCode(input);
    }, [authActions, setOldCode]);

    const onSubmitNewSecurityCode = useCallback((input: string) => {
        setNewCode(input);
    }, [authActions, setNewCode]);

    const onConfirmNewSecurityCode = useCallback((input: string) => {
        if (input !== newCode) {
            setConfirmError('common:incorrectCodeConfirmation');
            
            return;
        }

        authActions.setSecurityCode(newCode, oldCode, true);
    }, [authActions, setConfirmError, oldCode, newCode]);

    const onStartOver = useCallback(() => {
        setNewCode('');
        setVerifyCodeError('');
        setConfirmError('');
    }, [setNewCode]);

    const drawContent = useCallback(() => {
        if (!authState.isCodeReset) {
            return <Trans ns='common' i18nKey='checkYourEmailInfo' values={{ email }} components={{ strong: <strong /> }} />;
        }

        return (
            <div>
                <Trans ns='common' i18nKey='checkYourEmailInfo' values={{ email }} components={{ strong: <strong /> }} />
                <CodeResent />
            </div>
        );
    }, [authState]);

    useEffect(() => {
        if (authState.verifyCodeError) {
            setVerifyCodeError(authState.verifyCodeError);
        }

        if (authState.resetSecurityCodeError) {
            setVerifyCodeError(authState.resetSecurityCodeError);
        }

        if (authState.setNewCodeError) {
            setConfirmError(authState.setNewCodeError);
        }

        if (authState.isCodeValid) {
            setIsCodeVerified(authState.isCodeValid);
        }
    }, [setConfirmError, setVerifyCodeError, setIsCodeVerified, authState]);

    const isSendingRequest = authState.isSendingResetSecurityCode
        || authState.isVerifyingSecurityCode
        || authState.isSendingNewSecurityCode
        || false;

    return (
        <div class={styles.container}>
            {
                isSendingRequest ? 
                    <div class={styles.spinner}>
                        <Spinner size='large' />
                    </div> :
                    !isCodeVerified ?
                        <SecurityCode
                            key='temp-code'
                            title={t('common:checkYourEmail')}
                            subtitle={drawContent()}
                            buttonText={t('common:continue')}
                            onConfirm={onSubmitCurrentSecurityCode}
                            onChange={onChange}
                            errorMessageKey={verifyCodeError}
                            footerText={t('common:resendRecoveryCode')}
                            onFooterClick={onResendCodeClicked}
                        /> : 
                        <SecurityCode
                            key={confirmError ? 'verify-code-error' : newCode ? 'set-new-code' : 'verify-new-code'}
                            title={newCode ? t('common:confirmNewSecurityCode') : t('common:setNewSecurityCode')}
                            buttonText={newCode ? t('common:confirm'): t('common:continue')}
                            onConfirm={newCode ? onConfirmNewSecurityCode : onSubmitNewSecurityCode}
                            onChange={onChange}
                            errorMessageKey={confirmError}
                            errorAction={onStartOver}
                        />
            }
        </div>
    );
};

export default ForgotSecurityCode;
