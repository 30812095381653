import { CognitoAccessToken } from 'amazon-cognito-identity-js';
import { IApiClient } from '../utils/ApiClient';
import { IUserAuthenticationInfo, IVerifyService, PushChallengeStatus, SmsChallengeStatus } from './types';

export class VerifyService implements IVerifyService {
    constructor(private readonly apiClient: IApiClient) {

    }

    async getUserAuthenticationInfo(restrictedToken: CognitoAccessToken): Promise<IUserAuthenticationInfo> {
        const userId = restrictedToken.decodePayload().sub;

        const userAuthInfo = await this.apiClient.get<IUserAuthenticationInfo>(`/users/${encodeURIComponent(userId)}/auth-info`, {
            headers: {
                'Authorization': `Bearer ${restrictedToken.getJwtToken()}`,
            }
        });

        return userAuthInfo.data;
    }

    async resolveSmsChallenge(restrictedToken: CognitoAccessToken, challengeId: string, code: string): Promise<SmsChallengeStatus> {
        const { sub: userId } = restrictedToken.payload;

        const result = await this.apiClient.post<{ status: SmsChallengeStatus }>(`/users/${encodeURIComponent(userId)}/challenges/sms/${encodeURIComponent(challengeId)}/resolve`, {
            authPayload: code,
        }, {
            headers: {
                'Authorization': `Bearer ${restrictedToken.getJwtToken()}`,
            },
        });

        return result.data.status;
    }

    async getPushAuthChallengeStatus(restrictedToken: CognitoAccessToken, challengeId: string): Promise<PushChallengeStatus> {
        const { sub: userId } = restrictedToken.payload;

        const result = await this.apiClient.get<{ status: PushChallengeStatus }>(`/users/${encodeURIComponent(userId)}/challenges/push/${encodeURIComponent(challengeId)}/status`, {
            headers: {
                'Authorization': `Bearer ${restrictedToken.getJwtToken()}`,
            },
        });

        return result.data.status;
    }
}