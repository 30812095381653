import { IConfig } from '.';

export const getStagingConfig = (): IConfig => {
    return {
        ENV: 'staging',
        URL: 'https://auth-staging.payhawk.com',
        API_URL: 'https://api.staging.payhawk.com',
        VERIFY_API_URL: 'https://verify.staging.payhawk.com',
        CARDS_API_URL: 'https://qemsmu640m.execute-api.eu-central-1.amazonaws.com/default',
        PORTAL_URL: 'https://portal.staging.payhawk.com',
        AUTHENTICATION: {
            region: 'eu-central-1',
            userPoolId: 'eu-central-1_PnD63AMei',
            endpoint: 'https://cognito-staging.payhawk.com/',
            ssoEndpoint: 'https://id-staging.payhawk.com/',
            clientId: {
                web: {
                    restricted: '1ak6ccjhka2n3jp98tdb3fq3qb',
                    singleFactor: '5ri2nlr56grficuq36nm9p0gr4',
                    sms: 'h11afm66onuf5hqj0r3ji1qhj',
                    pushAuth: '4ti6g291vi85qpvmd9pm544it2'
                },
                mobile: {
                    restricted: '1ak6ccjhka2n3jp98tdb3fq3qb',
                    singleFactor: '2b5alhorsvlbgk14hkoopuflqu',
                    sms: '62bg1qcgvmlq1950cu2g0d6cks',
                    pushAuth: '2bjjcn0o90m0ftdjf43na5lb9m'
                },
                wallet: {
                    restricted: '1ak6ccjhka2n3jp98tdb3fq3qb',
                    singleFactor: '2b5alhorsvlbgk14hkoopuflqu',
                    sms: '62bg1qcgvmlq1950cu2g0d6cks',
                    pushAuth: '2bjjcn0o90m0ftdjf43na5lb9m'
                },
            }
        },
        RUM: {
            GUEST_ROLE_ARN: 'arn:aws:iam::447512646508:role/RUM-Monitor-eu-central-1-447512646508-3102030984071-Unauth',
            IDENTITY_POOL_ID: 'eu-central-1:26d902c4-62b6-497d-bfcb-2c11599fef37',
            APPLICATION_ID: '013f7958-339b-4f19-a352-a9b2ef29c7cd'
        }
    };
};
