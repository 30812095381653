import { h } from 'preact';
import { useCallback, useState } from 'preact/hooks';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../auth';
import { getPlatform, getSearch, Platform } from '../../utils/Browser';

import styles from './Login.module.scss';
import {
    Button,
    classNames,
    InputState,
    ITextInputOnChangeProps,
    TextInput
} from '@payhawk/hawky-react';
import { TextInputAutoFocus } from '../../utils/TextInputAutoFocus';

const PasswordInput = 'password';

const LoginPassword: preact.FunctionalComponent = () => {
    const [authState, authActions] = useAuth();
    const [password, setPassword] = useState('');
    const isWalletAuthentication = getPlatform() === Platform.wallet;

    const { t } = useTranslation(['common']);

    const onInputChange = useCallback(({ name, value }: ITextInputOnChangeProps) => {
        switch (name) {
            case PasswordInput:
                setPassword(value);
                break;
            default:
                break;
        }
    }, [setPassword]);

    const onSubmit = useCallback(async (e: h.JSX.TargetedEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!authState.email || !password) {
            return;
        }

        await authActions.signIn(authState.email, password);
    }, [authState.email, password, authActions]);

    return (
        <div class={styles.container}>
            <div className={styles.title}>{t('common:enterPassword')}</div>
            <div className={styles.subtitle}>{t('common:for')} {authState.email}</div>
            <div class={styles.wrap}>
                <form onSubmit={onSubmit}>
                    <TextInputAutoFocus
                        className='mt-24'
                        label={t('common:password')}
                        type='password'
                        id={PasswordInput}
                        name={PasswordInput}
                        value={password}
                        onChange={onInputChange}
                        isReadOnly={authState.isSigningIn}
                        placeholder={t('common:enterPassword')}
                        isRequired
                        state={authState.signInError ? InputState.Error : InputState.None}
                        stateText={authState.signInError ? authState.signInError : ''}
                    />

                    <Button
                        className='mt-32'
                        label={t('common:login')}
                        isExpanded
                        type='submit'
                        isDisabled={authState.isSigningIn}
                        isLoading={authState.isSigningIn}
                    />

                    { isWalletAuthentication
                        ? null
                        : (
                            <div class={styles.actions}>
                                <a className={classNames(styles.link, 'mr-32')} href='/'>{t('common:back')}</a>

                                <a class={styles.link} href={`/reset-password${getSearch()}`}>
                                    {t('common:forgotPassword')}
                                </a>
                            </div>
                        )}
                </form>
            </div>
        </div>
    );
};

export default LoginPassword;
