export enum Platform {
    wallet = 'wallet',
    mobile = 'mobile',
    web = 'web',
}

export enum AuthenticationType {
    Restricted = 'restricted',
    SingleFactor = 'singleFactor',
    Sms = 'sms',
    PushAuth = 'pushAuth',
}

export type ClientMap = {
    [key in AuthenticationType]: string;
};
export type ClientsMap = {
    [key in Platform]: ClientMap;
};

export function getPathname() {
    if (typeof window !== 'undefined') {
        return window.location.pathname;
    }

    return '';
}

export function getSearch() {
    if (typeof window !== 'undefined') {
        return window.location.search;
    }

    return '';
}

export function getPlatform(): Platform {
    const query = new URLSearchParams(getSearch());
    const platform = query.get('platform') || '';

    switch (platform.toLowerCase()) {
        case Platform.wallet:
            return Platform.wallet;
        case Platform.mobile:
            return Platform.mobile;
        default:
            return Platform.web;
    }
}

export const copyToClipboard = (str: string): void => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
};

export const runInInterval = (f: () => void, numberOfTimes: number = 1, delay: number = 20) => {
    let counter = 0;
    const intervalId = setInterval(() => {
        if (counter < numberOfTimes) {
            f();
            counter++;
        } else {
            clearInterval(intervalId);
        }
    }, delay);
};