import { Fragment, h } from 'preact';
import { useCallback, useEffect, useMemo, useState } from 'preact/hooks';
import { TFunction, Trans, useTranslation } from 'react-i18next';
import { useAuth } from '../../auth';

import styles from './SignUp.module.scss';
import {
    Button,
    CodeField,
    InputState,
    ITextInputOnChangeProps,
    TextInput,
    Alert
} from '@payhawk/hawky-react';
import Error from './Error';
import { Location } from 'history';
import { TextInputAutoFocus } from '../../utils/TextInputAutoFocus';

interface ISignUpParams {
    location: Location;
}

const COGNITO_AUTH_PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,}$/;

const SignUp: preact.FunctionalComponent<ISignUpParams> = ({ location }) => {
    const [authState, authActions] = useAuth();
    const [state, setState] = useState<State>({
        password: '',
        confirmPassword: '',
        verificationCode: '',
        status: Status.SignUp,
        hasInvitation: false,
        hasResend: false,
        onboardingFlow: undefined,
        opportunityId: undefined,
    });

    if (authState.isLoginWithTempPassword && state.status !== Status.ConfirmSignUp) {
        setState((s) => ({ ...s, status: Status.ConfirmSignUp }));
    }

    const { t } = useTranslation(['common']);

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const onboardingFlow = query.get('flow') || undefined;
        const opportunityId = query.get('customer') || undefined;

        setState((s) => ({ ...s, onboardingFlow, opportunityId }));

        const code = query.get('code');
        const userId = query.get('userId');
        const email = query.get('email') || undefined;
        const hasInvitation = !!(code && userId);

        if (hasInvitation) {
            setState((s) => ({ ...s, hasInvitation, verificationCode: code }));
            authActions.verifyInvitation(userId, code, email);
        }

        return () => {
            authActions.cleanSignUp();
        };
    }, [authActions, location.search, setState]);

    useEffect(() => {
        if (authState.userId) {
            if (authState.tempPassword) {
                setState((s) => ({ ...s, status: Status.ConfirmSignUp }));
            } else {
                setState((s) => ({ ...s, status: Status.VerifySignUp }));
            }

            return;
        }

        setState((s) => ({ ...s, status: Status.SignUp }));
    }, [authState.userId, authState.tempPassword, setState]);

    const onInputChange = useCallback(
        ({ name, value }: ITextInputOnChangeProps) => {
            switch (name) {
                case 'email':
                    authActions.setEmail(value);
                    break;
                case 'verification-code':
                    setState((s) => ({ ...s, verificationCode: value }));
                    break;
                case 'password':
                    setState((s) => ({ ...s, password: value }));
                    break;
                case 'confirm-password':
                    setState((s) => ({ ...s, confirmPassword: value }));
                    break;
                default:
                    break;
            }
        },
        [authActions, setState]
    );

    const onVerificationCodeChanged = useCallback((code: string) => {
        setState((s) => ({ ...s, verificationCode: code }));
    }, [authActions, setState]);

    const signUp = useCallback(
        async () => {
            await authActions.signUp(authState.email, state.onboardingFlow, state.opportunityId);
        },
        [authActions, authState.email, state.onboardingFlow, state.opportunityId]
    );

    const verifySignUp = useCallback(
        async () => {
            await authActions.verifySignUp(authState.userId!, state.verificationCode);
        },
        [authActions, authState.userId, state.verificationCode]
    );

    const confirmSignUp = useCallback(
        async () => {
            await authActions.confirmSignUp(
                authState.email,
                authState.userId!,
                authState.tempPassword!,
                state.password,
                state.confirmPassword,
                state.verificationCode,
                authState.isLoginWithTempPassword,
            );
        },
        [authActions, state, authState.email, authState.tempPassword, authState.userId, authState.isLoginWithTempPassword]
    );

    const resendVerificationCode = useCallback(async () => {
        await authActions.resendVerificationCode(authState.email, state.onboardingFlow);
        setState((s) => ({ ...s, hasResend: true }));
    }, [authState.email, authActions]);

    const contactSupport = useCallback(async () => {
        authActions.contactSupport();
    }, [authState.email, authActions]);

    const submit = useCallback(async (e: h.JSX.TargetedEvent<HTMLFormElement>) => {
        e.preventDefault();

        if(state.status === Status.SignUp) {
            return await signUp();
        }

        if(state.status === Status.VerifySignUp) {
            return await verifySignUp();
        }

        return await confirmSignUp();
    }, [state, authState, authActions]);

    const alertDescription = () => {
        return <ul className={styles.info}>
            <li>- {t('common:checkSpamFolder')}</li>
            <li>- {t('common:askAdministratorForSupport')}</li>
            <li>- <span className={styles.support} onClick={contactSupport}>{t('common:contactUsForSupport')}</span></li>
        </ul>;
    };

    const changeEmailAddress = useCallback(
        async () => {
            setState((s) => {
                return {
                    ...s,
                    password: '',
                    confirmPassword: '',
                    verificationCode: '',
                    status: Status.SignUp,
                    hasInvitation: false,
                    hasResend: false
                };
            });
            authActions.cleanSignUp();
        },
        [authActions, setState]
    );

    const passwordRegex = new RegExp(COGNITO_AUTH_PASSWORD_REGEX);

    const isPasswordValid = (password: string) => {
        return passwordRegex.test(password);
    };

    const isValidPasswordInput = useMemo(() => {
        if (state.password === '') {
            return true;
        }

        if (!isPasswordValid(state.password)) {
            return false;
        }

        return true;
    }, [state.password]);

    const arePasswordsMatching = useMemo(() => {
        if (state.confirmPassword === '') {
            return true;
        }

        return state.password === state.confirmPassword;
    }, [state.password, state.confirmPassword]);

    const isConfirmSignUpButtonDisabled = useMemo(() => {
        return state.password.length === 0
        || !isPasswordValid(state.password)
        || state.password !== state.confirmPassword;
    }, [state.password, state.confirmPassword]);

    const isReadOnly = useMemo(() => {
        return authState.isSigningUp && !authState.isLoginWithTempPassword;
    }, [authState.isSigningUp, authState.isLoginWithTempPassword]);

    return (
        authState.registrationError ?
            <Error /> :
            <div class={styles.container}>
                <div class={styles.wrap}>
                    <div class={styles.title}>{getDescription(state.status, t)}</div>
                    <div class={styles.subtitle}>{getSubTitle(state.status, authState.email)}</div>

                    <form onSubmit={submit}>
                        {/* SignUp */}
                        {state.status === Status.SignUp ?
                            <Fragment>
                                <TextInputAutoFocus
                                    className='mt-40'
                                    label={t('common:email')}
                                    id='email'
                                    name='email'
                                    type='email'
                                    value={authState.email}
                                    onChange={onInputChange}
                                    isReadOnly={authState.isSigningUp || !!authState.userId}
                                    isRequired
                                    state={authState.signUpError ? InputState.Error : InputState.None}
                                    stateText={authState.signUpError ? authState.signUpError : ''}
                                    placeholder={t('common:emailPlaceholder')}
                                />
                                <Button
                                    className='mt-32'
                                    label={t('common:actions.signUp')}
                                    isExpanded
                                    type='submit'
                                    isLoading={authState.isSigningUp}
                                    isDisabled={authState.isSigningUp}
                                />
                            </Fragment>
                            : null
                        }
                        {/* VerifySignUp */}
                        {state.status === Status.VerifySignUp ? (
                            <Fragment>
                                <div class={styles.verify}>
                                    {/* After clicking resend */}
                                    {state.hasResend ?
                                        <Alert
                                            className={styles.alert}
                                            startTitle={t('common:stillNotReceivingAnEmail')}
                                            type='info'
                                            description={alertDescription()} />
                                        : null}

                                    <div class={styles.code}>{t('common:enterTheCodeBelow')}</div>

                                    <CodeField
                                        key={authState.signUpError ? 'code-error' : 'not-submitted'}
                                        digitCount={6}
                                        onComplete={onVerificationCodeChanged}
                                    />
                                </div>
                                <Button
                                    className='mt-32'
                                    label={t('common:actions.verifyEmail')}
                                    isExpanded
                                    type='submit'
                                    isLoading={authState.isSigningUp}
                                    isDisabled={authState.isSigningUp}
                                />
                            </Fragment>
                        ) : null}

                        {/* Confirm Password ConfirmSignUp*/}
                        {state.status === Status.ConfirmSignUp ? (
                            <Fragment>
                                <TextInput
                                    className='mb-24 mt-40'
                                    type='email'
                                    label={t('common:yourWorkEmail')}
                                    id='email'
                                    name='email'
                                    value={authState.email}
                                    isReadOnly
                                />

                                <TextInput
                                    className='mb-24'
                                    label={t('common:password')}
                                    type='password'
                                    placeholder={t('common:pickAPassword')}
                                    id='password'
                                    name='password'
                                    value={state.password}
                                    onChange={onInputChange}
                                    isReadOnly={isReadOnly}
                                    isRequired
                                    helperText={isValidPasswordInput ? t('common:passwordPlaceholder') : ''}
                                    state={!isValidPasswordInput ? InputState.Error : InputState.None}
                                    stateText={!isValidPasswordInput ? t('common:passwordPlaceholder') : ''}
                                />

                                <TextInput
                                    label={t('common:confirmPassword')}
                                    placeholder={t('common:retypePassword')}
                                    type='password'
                                    id='confirm-password'
                                    name='confirm-password'
                                    value={state.confirmPassword}
                                    onChange={onInputChange}
                                    isReadOnly={isReadOnly}
                                    isRequired
                                    state={!arePasswordsMatching ? InputState.Error : InputState.None}
                                    stateText={!arePasswordsMatching ? t('common:passwordsDontMatch') : ''}
                                />
                                <Button
                                    className='mt-32'
                                    label={t('common:actions.createAccount')}
                                    isExpanded
                                    type='submit'
                                    isLoading={isReadOnly}
                                    isDisabled={isConfirmSignUpButtonDisabled}
                                />
                            </Fragment>
                        ) : null}

                        {/* VerifySignUp  actions */}
                        {state.status === Status.VerifySignUp ?
                            <div class={styles.resend}>
                                <Button
                                    size='small'
                                    skin='link'
                                    label={t('common:changeEmailAddress')}
                                    onClick={changeEmailAddress}
                                    type='submit'
                                />

                                <Button
                                    size='small'
                                    skin='link'
                                    className='ml-32'
                                    label={t('common:resendVerificationCode')}
                                    onClick={resendVerificationCode}
                                    type='submit'
                                    isLoading={authState.isResendingVerificationCode}
                                />
                            </div>

                            : null}

                    </form>
                    {state.status === Status.SignUp ?
                        <div class={styles.actions}>
                            <Trans
                                ns='common'
                                i18nKey='alreadyHaveAnAccount'
                                components={{ a: <a href={`/${location.search}}`} title='Login' /> }}
                            />
                        </div>
                        : null
                    }

                </div>
            </div>
    );
};

function getDescription(status: Status, t: TFunction<'common'[]>) {
    switch (status) {
        case Status.SignUp:
            return t('common:enterWorkEmail');
        case Status.VerifySignUp:
            return t('common:checkYourEmail');
        case Status.ConfirmSignUp:
            return t('common:setPassword');
        default:
            break;
    }
}

function getSubTitle(status: Status, email?: string) {
    switch (status) {
        case Status.VerifySignUp:
            return <Trans ns='common' i18nKey='verifySignUpDescription' values={{ email }} components={{ strong: <strong /> }} />;
        default:
            break;
    }
}

enum Status {
    SignUp,
    VerifySignUp,
    ConfirmSignUp,
}

type State = {
    password: string;
    confirmPassword: string;
    verificationCode: string;
    status: Status;
    hasInvitation: boolean;
    hasResend: boolean;
    onboardingFlow?: string,
    opportunityId?:string,
};

export default SignUp;
