import { useTranslation } from 'react-i18next';
import { h } from 'preact';

import {
    Alert,
    Button,
    classNames,
    CodeField,
    ContentHeader,
    Text,
} from '@payhawk/hawky-react';

import styles from './CompleteUserProfile.module.scss';

import { useEffect, useState } from 'preact/hooks';
import { useAuth } from '../../../auth';
import { useCallback } from 'react';
import { IUserInfo } from '../../../services';

interface ICompletePhoneVerificationProps {
    userInfo: IUserInfo;
    onChangePhoneNumber: () => void;
}

export const CompletePhoneVerification = ({ userInfo, onChangePhoneNumber }: ICompletePhoneVerificationProps) => {
    const { t } = useTranslation(['user']);

    const [ authState, _, multiFactorAuthActions ] = useAuth();
    const { restrictedSession, challenge } = authState.multiFactorAuth;

    const [code, setCode] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [isVerifying, setIsVerifying] = useState<boolean>(false);
    const [hasResend, setHasResend] = useState<boolean>(false);

    const sendVerificationCode = useCallback(async () => {
        if (challenge?.data.type !== 'PAYHAWK_USER_PROFILE_SETUP' || !userInfo.phoneNumber || !restrictedSession?.user) {
            return;
        }

        try {
            await multiFactorAuthActions.initPhoneVerification(restrictedSession.user);
        } catch (e: any) {
            setError(e.message || t('user:phoneVerification.sendVerificationCodeError'));
        }
    }, [challenge, restrictedSession?.user]);

    useEffect(() => {
        sendVerificationCode();
    }, [sendVerificationCode]);

    const verifyPhone = async () => {
        if (!restrictedSession || challenge?.data.type !== 'PAYHAWK_USER_PROFILE_SETUP') {
            return;
        }

        if (!code) {
            setError(t('user:phoneVerification.verificationCodeRequired'));

            return;
        }

        setIsVerifying(true);
        setError('');

        try {
            await multiFactorAuthActions.verifyPhone(restrictedSession.user, code);
        } catch (e: any) {
            setError(e.message);
            setIsVerifying(false);

            return;
        }

        await multiFactorAuthActions.resolveChallenge(challenge, restrictedSession);

        setIsVerifying(false);
    };

    const resendVerificationCode = async () => {
        await sendVerificationCode();
        setHasResend(true);
    };

    if (!userInfo.phoneNumber) {
        return null;
    }

    const AlertDescription = () => {
        return (
            <div className='d-flex'>
                <span>{t('user:phoneVerification.verificationCodeResendSub')}</span>
                <Button
                    className={classNames('ml-4', styles.subButton, 'custom-intercom-launcher')}
                    skin='link'
                    label={t('user:phoneVerification.contactUsForSupport')}
                />
            </div>
        );
    };

    return (
        <div className={styles.container}>
            <ContentHeader
                type='large-centered'
                title={t('user:phoneVerification.confirmPhone')}
                startSubtitle={t('user:phoneVerification.enterVerificationCodeSendTo', { phoneNumber: userInfo.phoneNumber })}
            />
            {hasResend && (
                <Alert
                    className='mb-40'
                    type='info'
                    startTitle={t('user:phoneVerification.verificationCodeResendMain')}
                    description={<AlertDescription />}
                />
            )}
            {!!error && (
                <Alert
                    className='mb-40'
                    type='error'
                    description={error}
                />
            )}
            <div className='my-40'>
                <Text.BodyLarge>{t('user:phoneVerification.enterCode')}</Text.BodyLarge>
                <CodeField onComplete={setCode} />
            </div>
            <Button
                label={t('user:phoneVerification.verifyPhone')}
                isLoading={isVerifying}
                onClick={verifyPhone}
                isExpanded
            />
            <div className='g-6-8 mt-24'>
                <Button
                    className={classNames('g-col-3', styles.subButton)}
                    label={t('user:phoneVerification.changePhone')}
                    onClick={onChangePhoneNumber}
                    skin='link'
                />

                <Button
                    className={classNames('g-col-3', styles.subButton)}
                    label={t('user:phoneVerification.resendCode')}
                    onClick={resendVerificationCode}
                    skin='link'
                />
            </div>
        </div >
    );
};

CompletePhoneVerification.displayName = 'CompletePhoneVerification';
