export const SUPPORTED_COUNTRIES: ICountryModel[] = [
    {
        fullName: 'Austria',
        code: 'AT',
        culture: 'de-at',
    },
    {
        fullName: 'Andorra',
        code: 'AD',
        culture: 'ad',
    },
    {
        fullName: 'Belgium',
        code: 'BE',
        culture: 'nl-be',
    },
    {
        fullName: 'Bulgaria',
        code: 'BG',
        culture: 'bg-bg',
    },
    {
        fullName: 'Croatia',
        code: 'HR',
        culture: 'hr',
    },
    {
        fullName: 'Cyprus',
        code: 'CY',
        culture: 'el',
    },
    {
        fullName: 'Czech Republic',
        code: 'CZ',
        culture: 'cs',
    },
    {
        fullName: 'Denmark',
        code: 'DK',
        culture: 'da',
    },
    {
        fullName: 'Estonia',
        code: 'EE',
        culture: 'et',
    },
    {
        fullName: 'Finland',
        code: 'FI',
        culture: 'fi',
    },
    {
        fullName: 'France',
        code: 'FR',
        culture: 'fr-fr',
    },
    {
        fullName: 'Germany',
        code: 'DE',
        culture: 'de-de',
    },
    {
        fullName: 'Greece',
        code: 'GR',
        culture: 'el',
    },
    {
        fullName: 'Gibraltar',
        code: 'GI',
        culture: 'en-gb',
    },
    {
        fullName: 'Hungary',
        code: 'HU',
        culture: 'hu',
    },
    {
        fullName: 'Iceland',
        code: 'IS',
        culture: 'is',
    },
    {
        fullName: 'Ireland',
        code: 'IE',
        culture: 'en-ie',
    },
    {
        fullName: 'Italy',
        code: 'IT',
        culture: 'it-it',
    },
    {
        fullName: 'Isle of Man',
        code: 'IM',
        culture: 'en-gb',
    },
    {
        fullName: 'Latvia',
        code: 'LV',
        culture: 'lv',
    },
    {
        fullName: 'Liechtenstein',
        code: 'LI',
        culture: 'de-li',
    },
    {
        fullName: 'Lithuania',
        code: 'LT',
        culture: 'lt',
    },
    {
        fullName: 'Luxemburg',
        code: 'LU',
        culture: 'fr-lu',
    },
    {
        fullName: 'Malta',
        code: 'MT',
        culture: 'mt',
    },
    {
        fullName: 'Monaco',
        code: 'MC',
        culture: 'mc',
    },
    {
        fullName: 'Netherlands',
        code: 'NL',
        culture: 'nl-nl',
    },
    {
        fullName: 'Norway',
        code: 'NO',
        culture: 'no-no',
    },
    {
        fullName: 'Poland',
        code: 'PL',
        culture: 'pl',
    },
    {
        fullName: 'Portugal',
        code: 'PT',
        culture: 'pt-pt',
    },
    {
        fullName: 'Romania',
        code: 'RO',
        culture: 'ro',
    },
    {
        fullName: 'San Marino',
        code: 'SM',
        culture: 'sm',
    },
    {
        fullName: 'Slovakia',
        code: 'SK',
        culture: 'sk',
    },
    {
        fullName: 'Slovenia',
        code: 'SI',
        culture: 'sl',
    },
    {
        fullName: 'Spain',
        code: 'ES',
        culture: 'es-es',
    },
    {
        fullName: 'Sweden',
        code: 'SE',
        culture: 'sv-se',
    },
    {
        fullName: 'Switzerland',
        code: 'CH',
        culture: 'ch',
    },
    {
        fullName: 'United Kingdom',
        code: 'GB',
        culture: 'en-gb',
    },
];

const supportedCountriesByCode: { [code: string]: ICountryModel } = {};

SUPPORTED_COUNTRIES.forEach(c => supportedCountriesByCode[c.code] = c);

interface ICountryModel {
    fullName: string;
    code: string;
    culture: string;
}
