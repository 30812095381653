import { h } from 'preact';

import { createHashHistory } from 'history';
import { Route, Router } from 'preact-router';
import { AuthContextProvider } from './auth';
import { CONFIG } from './config/Config';
import ChangePassword from './pages/change-password/ChangePassword';
import LoginEmail from './pages/login/LoginEmail';
import LoginPassword from './pages/login/LoginPassword';
import PendingRegistration from './pages/reset-password/PendingRegistration';
import ResetPassword from './pages/reset-password/ResetPassword';
import ResetPasswordConfirm from './pages/reset-password/ResetPasswordConfirm';
import SignUp from './pages/sign-up/SignUp';
import VerifySecurityCode from './pages/card-details/VerifySecurityCode';
import ChangeSecurityCode from './pages/change-security-code/ChangeSecurityCode';
import ForgotSecurityCode from './pages/forgot-security-code/ForgotSecurityCode';
import { AuthService, MessageService, ServiceProvider } from './services';
import { createApiClient } from './utils/ApiClient';
import { VerifyService } from './services/VerifyService';
import { MultiFactorAuth } from './pages/multi-factor-auth/MultiFactorAuth';
import { UserService } from './services/UserService';
import { PushAuthSetupOptional } from './pages/multi-factor-auth/PushAuthSetupOptional';
import { DeclinedPushAuth } from './pages/multi-factor-auth/DeclinedPushAuth';
import { LostTrustedDevice } from './pages/multi-factor-auth/LostTrustedDevice';

if ((module as any).hot) {
    // tslint:disable-next-line:no-var-requires
    require('preact/debug');
}

import { AwsRum, AwsRumConfig } from 'aws-rum-web';

try {
  const config: AwsRumConfig = {
    sessionSampleRate: 1,
    guestRoleArn: CONFIG.RUM.GUEST_ROLE_ARN,
    identityPoolId: CONFIG.RUM.IDENTITY_POOL_ID,
    endpoint: 'https://dataplane.rum.eu-central-1.amazonaws.com',
    telemetries: ['errors','http'],
    allowCookies: true,
    enableXRay: false
  };

  const APPLICATION_VERSION: string = '1.0.0';

  const awsRum: AwsRum = new AwsRum(
    CONFIG.RUM.APPLICATION_ID,
    APPLICATION_VERSION,
    CONFIG.AUTHENTICATION.region,
    config
  );
} catch (error) {
  // Ignore errors thrown during CloudWatch RUM web client initialization
}

const apiClient = createApiClient(CONFIG.API_URL);
const payhawkCardsApiClient = createApiClient(CONFIG.CARDS_API_URL);
const verifyClient = createApiClient(CONFIG.VERIFY_API_URL);
const authService = new AuthService(apiClient, payhawkCardsApiClient);
const messenger = new MessageService(CONFIG.PORTAL_URL);
const verifyService = new VerifyService(verifyClient);
const userService = new UserService(apiClient);
const history = createHashHistory();

const App: preact.FunctionalComponent = () => {
    return (
        <div id='app'>
            <ServiceProvider authService={authService} messenger={messenger} verifyService={verifyService} userService={userService}>
                <AuthContextProvider>
                    <Router history={history}>
                        <Route path='/' component={() => <LoginEmail location={history.location} />} />
                        <Route path='/password' component={LoginPassword} />
                        <Route path='/reset-password' component={ResetPassword} />
                        <Route path='/reset-password-confirm' component={ResetPasswordConfirm} />
                        <Route path='/reset-password-pending-registration' component={PendingRegistration} />
                        <Route path='/sign-up' component={() => <SignUp location={history.location} />} />
                        <Route path='/multi-factor' component={MultiFactorAuth} />
                        <Route path='/multi-factor/push-auth-setup' component={PushAuthSetupOptional} />
                        <Route path='/multi-factor/push-auth-declined' component={DeclinedPushAuth} />
                        <Route path='/multi-factor/lost-trusted-device' component={LostTrustedDevice} />

                        <Route path='/change-password' component={() => <ChangePassword location={history.location} />} />
                        <Route path='/card-details' component={VerifySecurityCode} />
                        <Route path='/change-security-code' component={ChangeSecurityCode} />
                        <Route path='/forgot-security-code' component={ForgotSecurityCode} />
                    </Router>
                </AuthContextProvider>
            </ServiceProvider>
        </div>
    );
};

export default App;
