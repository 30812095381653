import { CognitoAccessToken } from 'amazon-cognito-identity-js';
import { IApiClient } from '../utils/ApiClient';
import { IEditUserInfo, IUserInfo, IUserService } from './types';
import { Platform } from '../utils/Browser';
import { AxiosError } from 'axios';

export class UserService implements IUserService {
    constructor(
        private readonly apiClient: IApiClient) {
    }

    async getUserData(restrictedToken: CognitoAccessToken): Promise<IUserInfo | undefined> {
        try {
            const result = await this.apiClient.get<IUserInfo>(`/user`, {
                headers: {
                    'Authorization': `Bearer ${restrictedToken.getJwtToken()}`,
                },
                params: { platform: Platform.web }
            });

            return result.data;
        } catch (e: any) {
            handleError(e);
        }
    }

    async updateUserData(restrictedToken: CognitoAccessToken, id: string, userData: Partial<IEditUserInfo>) {
        try {
            await this.apiClient.put(`/user`, {
                id,
                ...userData,
            }, {
                headers: {
                    'Authorization': `Bearer ${restrictedToken.getJwtToken()}`,
                },
            });
        } catch (e: any) {
            handleError(e);
        }
    }

    async verifyPhone(restrictedToken: CognitoAccessToken, code: string): Promise<void> {
        const body: IUserPhoneVerification = {
            code,
        };

        try {
            await this.apiClient.post(`/user/verify-phone`, body, {
                headers: {
                    'Authorization': `Bearer ${restrictedToken.getJwtToken()}`,
                }
            });
        } catch (e: any) {
            handleError(e);
        }
    }
}

function handleError(e: AxiosError) {
    if (e.response && e.response.data) {
        throw Error(e.response.data.message);
    }

    throw e;
}

export interface IUserPhoneVerification {
    code: string;
}