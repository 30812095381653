import { h } from 'preact';
import { useCallback, useEffect } from 'preact/hooks';

import { Trans, useTranslation } from 'react-i18next';

import { AuthErrorType, useAuth } from '../../auth';
import {
    Button,
    classNames,
    Icon,
    InputState,
    ITextInputOnChangeProps,
    Spinner,
} from '@payhawk/hawky-react';
import styles from './Login.module.scss';
import { getPlatform, getSearch, Platform } from '../../utils/Browser';
import { Location } from 'history';
import { TextInputAutoFocus } from '../../utils/TextInputAutoFocus';

const UserNameInput = 'username';

interface ILoginProps {
    location: Location;
}

const LoginEmail: preact.FunctionalComponent<ILoginProps> = ({ location }) => {
    const [authState, authActions] = useAuth();
    const { t } = useTranslation(['common']);

    const hasRegistrationError = authState.externalSignInError === AuthErrorType.RegistrationErrorNoSuchUser;
    const isWalletAuthentication = getPlatform() === Platform.wallet;

    useEffect(() => {
        const baseSearch = getSearch(); // this is the query that's part of the base path
        const search = location.search; // this is the query that's part of the hash path

        authActions.processAuthenticationParams(baseSearch, search);
    }, []);

    if (authState.isSigningInWithExternalProvider) {
        return (
            <div className='d-flex flex-column align-items-center justify-content-center h-100'>
                <Spinner size='large'/>
            </div>
        );
    }

    const onInputChange = useCallback(({ name, value }: ITextInputOnChangeProps) => {
        switch (name) {
            case UserNameInput:
                authActions.setEmail(value);
                break;
            default:
                break;
        }
    }, [authActions]);

    const onGoogleSocialSignIn = useCallback(() => {
        authActions.externalProviderSignIn('Google');
    }, [authActions]);

    const onSubmit = useCallback(async (e: h.JSX.TargetedEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!authState.email) {
            return;
        }

        await authActions.checkEmail(authState.email);
    }, [authActions, authState.email]);

    return (
        <div class={styles.container}>
            <div className={styles.title}>{t('common:loginText')}</div>
            {hasRegistrationError
                ?  <div class={classNames('g-12-8', styles.errorContainer)}>
                    <div className='g-col-1'>
                        <Icon name='triangle-warning' />
                    </div>
                    <div className='g-col-10 ml-8 d-flex-col text-left'>
                        <div className={classNames('mb-8', styles.errorTitle)}>{t('common:noUserFoundWithThisEmail')}</div>
                        <div className={styles.errorSubtitle}>
                            <span>{t('common:pleaseContactYourAdministrator')}</span>
                        </div>
                    </div>
                    <div className='g-col-1'/>
                </div>
                : null}
            <div className={styles.wrap}>
                <button name='googleSignUpButton' className={styles.button} onClick={onGoogleSocialSignIn}>
                    <svg viewBox='0 0 256 262' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='xMidYMid'>
                        <path d='M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027' fill='#4285F4' />
                        <path d='M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1' fill='#34A853' />
                        <path d='M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782' fill='#FBBC05' />
                        <path d='M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251' fill='#EA4335' />
                    </svg>

                    <span>{t('common:continueWithGoogle')}</span>
                </button>

                <div className={styles.divider}>
                    <div />
                    <span>{t('common:orUseEmail')}</span>
                    <div />
                </div>

                <form onSubmit={onSubmit}>
                    <TextInputAutoFocus
                        className='mt-24'
                        label={t('common:emailAddress')}
                        id={UserNameInput}
                        name={UserNameInput}
                        type='email'
                        value={authState.email}
                        onChange={onInputChange}
                        isReadOnly={authState.isCheckingEmail}
                        placeholder='email@company.com'
                        isRequired
                        state={authState.checkEmailError ? InputState.Error : InputState.None}
                        stateText={authState.checkEmailError ? authState.checkEmailError : ''}
                    />

                    <Button
                        className='mt-32 mb-40'
                        label={t('common:continue')}
                        isExpanded
                        type='submit'
                        isDisabled={authState.isCheckingEmail}
                        isLoading={authState.isCheckingEmail}
                    />
                </form>
                { isWalletAuthentication
                    ? null
                    : <div class={styles.text}>
                    <Trans
                        ns='common'
                        i18nKey='dontHaveAnAccount'
                        components={{ a: <a /> }}
                    />
                </div>}
            </div>
        </div>
    );
};

export default LoginEmail;
