import { PhoneNumberUtil } from 'google-libphonenumber';

export function getCountryCodeByPhoneNumber(phoneNumber: string): string | undefined {
    try {
        const parsedPhone = PhoneNumberUtil.getInstance().parse(phoneNumber);

        const countryCode = parsedPhone.getCountryCode();

        if (!countryCode) {
            return undefined;
        }

        return `+${countryCode}`;
    } catch (e) {
        return undefined;
    }
}

export function getNationalNumber(phoneNumber: string): string | undefined {
    try {
        const parsedPhone = PhoneNumberUtil.getInstance().parse(phoneNumber);

        return parsedPhone.getNationalNumber()?.toString();
    } catch (e) {
        return phoneNumber;
    }
}

export const validatePhoneNumber = (phoneNumber: string) => {
    const util = PhoneNumberUtil.getInstance();

    try {
        const parsedNumber = util.parse(phoneNumber);

        return util.isValidNumber(parsedNumber);
    } catch (e) {
        return false;
    }
};
