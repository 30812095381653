import { h } from 'preact';
import { useTranslation } from 'react-i18next';
import { Button } from '@payhawk/hawky-react';
import { useAuth } from '../../auth';
import styles from './SecurityCodeDisabled.module.scss';
import { useCallback, useEffect } from 'preact/hooks';

const SecurityCodeDisabled: preact.FunctionalComponent = () => {
    const [authState, authActions] = useAuth();
    const { t } = useTranslation(['common']);

    const onResetSecurityCodeClicked = useCallback(() => {
        authActions.resetSecurityCode();
    }, [authActions]);

    const onCancelClicked = useCallback(() => {
        authActions.cancelSecurityCodeChange();
    }, [authActions]);

    useEffect(() => {
        authActions.changeTitleToSecurityCodeDisabled();
    }, [authActions]);

    return (
        <div class={styles.container} key='security-code-disabled'>
            <div>
                {t('common:exceededMaxRetryCount')}
                <div className={styles.buttons}>
                    <Button label={t('common:resetSecurityCode')} onClick={onResetSecurityCodeClicked} className={styles.button} isExpanded />
                    <Button label={t('common:cancel')} onClick={onCancelClicked} skin='secondary' className={styles.button} isExpanded />
                </div>
            </div>
        </div>
    );
};

export default SecurityCodeDisabled;