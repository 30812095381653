import i18n, { InitOptions, LanguageDetectorModule, Services } from 'i18next';
import { initReactI18next } from 'react-i18next';

import { defaultNS, resources } from './i18n-exports';

const languageDetector: LanguageDetectorModule = {
    type: 'languageDetector',
    init: (services: Services, detectorOptions: object, i18nextOptions: InitOptions) => { /* use services and options */ },
    cacheUserLanguage: (lng: string) => { /* save user language */ },
    detect: () => {
        const params = new URLSearchParams(window.location.search);
        const lng = params.get('lng');

        return lng || 'en';
    },
};

i18n
    .use(languageDetector)
    .use(initReactI18next)
    .init({
        compatibilityJSON: 'v3',
        fallbackLng: 'en',
        supportedLngs: ['en', 'es', 'de', 'fr', 'it', 'nl', 'bg', 'pt', 'fi'],
        ns: ['auth', 'common', 'user'],
        defaultNS,
        resources,
        interpolation: {
            escapeValue: false,
        },
    });

i18n.services.formatter?.add('formatPossessive', (value: string) => {
    return value + value.endsWith('s') ? '\'' : '\'s';
});

export default i18n;
