import { Spinner } from '@payhawk/hawky-react';
import { h } from 'preact';

export const LoadingScreen = () => {
    return (
        <div className='d-flex flex-column align-items-center justify-content-center h-100'>
            <Spinner size='large'/>
        </div>
    );
};

LoadingScreen.displayName = 'LoadingScreen';