import { h } from 'preact';
import { useCallback } from 'preact/hooks';
import { useTranslation } from 'react-i18next';
import { Button } from '@payhawk/hawky-react';
import { useAuth } from '../../auth';
import { useUpdateHeight } from '../../utils/useUpdateHeight';
import ChangeSecurityCode from '../change-security-code/ChangeSecurityCode';
import CheckPassword from '../check-password/CheckPassword';
import styles from './InitSecurityCode.module.scss';

const InitSecurityCode: preact.FunctionalComponent = () => {
    const { t } = useTranslation(['common']);
    const [authState, authActions] = useAuth();

    const onContinue = useCallback(() => {
        authActions.showPasswordCheck();
    }, []);

    const onCancel = useCallback(() => {
        authActions.cancelSecurityCodeChange();
    }, []);

    useUpdateHeight();

    const { isShowPasswordCheck, passwordCheckSuccess } = authState;

    if (isShowPasswordCheck) {
        return <CheckPassword />;
    }

    if (passwordCheckSuccess) {
        return <ChangeSecurityCode />;
    }

    return (
        <div class={styles.container} key='init-security-code'>
            <div>
                {t('common:noSecurityCodeDescription')}
                <div className={styles.buttons}>
                    <Button label={t('common:continue')} onClick={onContinue} className={styles.button} isExpanded />
                    <Button label={t('common:cancel')} onClick={onCancel} skin='secondary' className={styles.button} isExpanded />
                </div>
            </div>
        </div>
    );
};

export default InitSecurityCode;
