import { h } from 'preact';
import { route } from 'preact-router';
import { useCallback, useEffect, useState } from 'preact/hooks';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../auth';
import { getSearch } from '../../utils/Browser';

import styles from './ResetPassword.module.scss';
import {
    Button,
    InputState,
    ITextInputOnChangeProps,
    TextInput
} from '@payhawk/hawky-react';
import React from 'react';
import { TextInputAutoFocus } from '../../utils/TextInputAutoFocus';

interface IState {
    newPassword: string;
    verificationCode: string;
}

const ResetPasswordConfirm: preact.FunctionalComponent = () => {
    const [authState, authActions] = useAuth();
    const [state, updateState] = useState<IState>({
        newPassword: '',
        verificationCode: '',
    });

    const { t } = useTranslation(['common']);

    useEffect(() => {
        if (!authState.email) {
            route('/reset-password');
        }
    }, [authState.email]);

    const onInputChange = useCallback(({ name, value }: ITextInputOnChangeProps) => {
        switch (name) {
            case 'new-password':
                updateState(s => ({ ...s, newPassword: value }));
                break;
            case 'verification-code':
                updateState(s => ({ ...s, verificationCode: value }));
                break;
            default:
                break;
        }
    }, [updateState]);

    const onSubmit = useCallback(async (e: h.JSX.TargetedEvent) => {
        e.preventDefault();
        authActions.resetPasswordConfirm(authState.email, state.newPassword, state.verificationCode);
    }, [authState.email, authActions, state]);

    const helperText = () => {
        return (<React.Fragment>
            <div>- {t('common:minEightCharacters')}</div>
            <div>- {t('common:atLeastOneSpecialCharacter')}</div>
            <div>- {t('common:atLeastOneNumber')}</div>
        </React.Fragment>);
    };

    const verificationCodeErrorMessage = authState?.resetPasswordError?.verificationCodeError || '';
    const newPasswordErrorMessage = authState?.resetPasswordError?.newPasswordError || '';

    return (
        <div class={styles.container}>
            <div class={styles.title}>{t('common:changePassword')}</div>
            <div class={styles.subtitle}>{t('common:enterVerificationCode')}</div>
            <div class={styles.wrap}>
                <form onSubmit={onSubmit}>
                    <TextInputAutoFocus
                        label={t('common:verificationCode')}
                        id='verification-code'
                        name='verification-code'
                        value={state.verificationCode}
                        onChange={onInputChange}
                        isReadOnly={authState.isResettingPassword}
                        isRequired
                        state={verificationCodeErrorMessage ? InputState.Error : InputState.None}
                        stateText={verificationCodeErrorMessage}
                        placeholder={t('common:enterYourCode')}
                    />

                    <TextInput
                        className='mt-24'
                        label={t('common:newPassword')}
                        id='new-password'
                        name='new-password'
                        type='password'
                        value={state.newPassword}
                        onChange={onInputChange}
                        isReadOnly={authState.isResettingPassword}
                        isRequired
                        state={newPasswordErrorMessage ? InputState.Error : InputState.None}
                        stateText={newPasswordErrorMessage ? newPasswordErrorMessage : ''}
                        placeholder={t('common:enterYourNewPassword')}
                        helperText={helperText()}
                    />

                    <Button
                        className='mt-32'
                        label={t('common:reset')}
                        isExpanded
                        type='submit'
                        isDisabled={authState.isResettingPassword}
                        isLoading={authState.isResettingPassword}
                    />

                    <div class={styles.actions}>
                        <span>{t('common:rememberedMyPassword')}</span>
                        <a href={`/${getSearch()}`} class={styles.link}>{t('common:loginText')}</a>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ResetPasswordConfirm;
