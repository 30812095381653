import { IMessageService, MessageCodes } from './types';

const parent = window.parent;
export class MessageService implements IMessageService {
    constructor(private readonly target: string) {

    }

    public contactSupport() {
        if (!parent) {
            return;
        }

        parent.postMessage({
            code: MessageCodes.CONTACT_SUPPORT,
        }, this.target);
    }

    public changeHeight(height?: number): void {
        if (!parent || !height) {
            return;
        }

        parent.postMessage({
            code: MessageCodes.CHANGE_HEIGHT,
            height
        }, this.target);
    }

    public noSecurityCodeModal() {
        if (!parent) {
            return;
        }

        parent.postMessage({
            code: MessageCodes.SHORT_MODAL,
        }, this.target);
    }

    public passwordModal(): void {
        if (!parent) {
            return;
        }

        parent.postMessage({
            code: MessageCodes.TALL_MODAL,
        }, this.target);
    }

    public enterCodeModal(): void {
        if (!parent) {
            return;
        }

        parent.postMessage({
            code: MessageCodes.TALL_MODAL,
        }, this.target);
    }

    public userSignedIn(session: IUserSession) {
        if (!parent) {
            return;
        }

        parent.postMessage({
            code: MessageCodes.SIGNED_IN,
            data: session,
        }, this.target);
    }

    public userSignInFailed(errorMessage: string): void {
        if (!parent) {
            return;
        }

        parent.postMessage({
            code: MessageCodes.ERROR,
            data: errorMessage,
        }, this.target);
    }

    public cardDetailsOpened() {
        if (!parent) {
            return;
        }

        parent.postMessage({
            code: MessageCodes.TALL_MODAL_WITH_BUTTON,
        }, this.target);
    }

    public securityCodeChangedSuccessfully() {
        if (!parent) {
            return;
        }

        parent.postMessage({
            code: MessageCodes.CHANGE_SECURITY_CODE_SUCCESS,
        }, this.target);
    }

    public securityCodeChangeCanceled() {
        if (!parent) {
            return;
        }

        parent.postMessage({
            code: MessageCodes.CHANGE_SECURITY_CODE_CANCELED,
        }, this.target);
    }

    public securityCodeDisabled() {
        if (!parent) {
            return;
        }

        parent.postMessage({
            code: MessageCodes.CHANGE_TITLE_SECURITY_CODE_DISABLED
        }, this.target);
    }

    public resetSecurityCode() {
        if (!parent) {
            return;
        }

        parent.postMessage({
            code: MessageCodes.RESET_SECURITY_CODE_SEND,
        }, this.target);
    }

    public async getUserSession(): Promise<IUserSession | undefined> {
        if (!parent) {
            throw Error('Failed to obtain user session: there is no parent.');
        }

        let eventHandler;
        try {
            const session: IUserSession = await new Promise((resolve, reject) => {
                eventHandler = (event: MessageEvent) => {
                    if (event.origin !== this.target) {
                        return;
                    }

                    const { code, data } = event.data;

                    if (code !== MessageCodes.SESSION_RESPONSE) {
                        return;
                    }

                    resolve(data);

                    // TODO: Add timeout reject?
                };

                window.addEventListener('message', eventHandler);

                parent.postMessage({
                    code: MessageCodes.SESSION_REQUEST,
                }, this.target);
            });

            return session;
        } finally {
            if (eventHandler) {
                window.removeEventListener('message', eventHandler);
            }
        }
    }
}