export function getValidString(argument: any, argumentName: string): string {
    if (typeof argument !== 'string') {
        throw Error(`${argumentName} is not a valid string.`);
    }

    const result = argument.trim();
    if (result.length === 0) {
        throw Error(`${argumentName} is empty.`);
    }

    return result;
}

export function getValidEmail(argument: any): string {
    const result = getValidString(argument, 'email').toLowerCase();

    return result;
}

export function isEmpty(argument: string) {
    if (typeof argument === 'string' && argument.trim()) {
        return false;
    }

    return true;
}