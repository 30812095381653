import { AuthenticationDetails, CognitoAccessToken, CognitoIdToken, CognitoRefreshToken, CognitoUser, CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';
import { CognitoErrorCode, ICardDetailsPayload } from '../auth';
import { CONFIG } from '../config/Config';
import { IApiClient } from '../utils/ApiClient';
import { getValidEmail, getValidString } from '../utils/ArgumentValidations';
import { AuthenticationType, getPlatform, Platform } from '../utils/Browser';
import { IAuthResult, IAuthService, IChallengeAuthResult, ICognitoChallenge, IFindIdentityProviderResult, IHasSecurityCodeResponse, IMultiFactorAuthChallenge, ISecurityCodeResponse, ISuccessAuthResult } from './types';

const CHALLENGE_DURATION_MINUTES = 5;

export interface ICodeExchangeRequestPayload {
    code: string;
    clientId: string;
}

export interface ICodeExchangeResponsePayload {
    idToken: string;
    accessToken: string;
    refreshToken: string;
}

export class AuthService implements IAuthService {
    constructor(
        private readonly apiClient: IApiClient,
        private readonly payhawkCardsApiClient: IApiClient) {
    }

    public async signIn(email: string, password: string): Promise<CognitoUser> {
        email = getValidEmail(email);
        password = getValidString(password, 'password');

        const restrictedUser = this.createCognitoUser(email, AuthenticationType.Restricted);

        await new Promise((resolve, reject) => {
            const authDetails = new AuthenticationDetails({
                Username: email,
                Password: password,
            });

            restrictedUser.authenticateUser(authDetails, {
                onSuccess: (s) => {
                    resolve(s);
                },
                onFailure: (err) => {
                    reject(err);
                },
                newPasswordRequired: (userAttr, reqAttr) => {
                    reject({
                        code: CognitoErrorCode.NewPasswordRequiredException
                    });
                },
            });
        });

        clearCachedUser(restrictedUser);

        return restrictedUser;
    }

    /**
     * Exchanges an authentication code for authentication tokens
     * @param {string} code The authentication code
     * @param {string} clientId The client id
     * @param {string} redirectUri The redirect URI the original authentication request has been made with
     * @returns {IUserSession} Valid authentication tokens
     */
    public async signInWithCode(code: string, clientId: string, redirectUri: string): Promise<CognitoUser> {
        const payload = {
            clientId,
            code,
            redirectUri,
        };

        try {
            const response = await this.apiClient.post<IUserSession>(`/exchange-auth-code`, payload);
            const userSession = new CognitoUserSession({
                AccessToken: new CognitoAccessToken({ AccessToken: response.data.accessToken }),
                IdToken: new CognitoIdToken({ IdToken: response.data.idToken }),
                RefreshToken: new CognitoRefreshToken({ RefreshToken: response.data.refreshToken })
            });

            const username = userSession.getAccessToken().decodePayload().username;
            const user = this.createCognitoUser(username, AuthenticationType.Restricted);
            user.setSignInUserSession(userSession);

            return user;
        } catch (e: any) {
            if (e.response?.data?.message) {
                throw new Error(e.response.data.message);
            }

            throw e;
        }
    }

    public async signInWithTempPassword(email: string, tempPassword: string, newPassword: string): Promise<CognitoUser> {
        email = getValidEmail(email);
        tempPassword = getValidString(tempPassword, 'tempPassword');
        newPassword = getValidString(newPassword, 'newPassword');

        const user = this.createCognitoUser(email, AuthenticationType.Restricted);

        await new Promise((resolve, reject) => {
            const authDetails = new AuthenticationDetails({
                Username: email,
                Password: tempPassword,
            });

            user.authenticateUser(authDetails, {
                onSuccess: (s) => {
                    reject('Failed to set password. Try using Forgot Password or contact support.');
                },
                onFailure: (err) => {
                    reject(err);
                },
                newPasswordRequired: (userAttr, reqAttr) => {
                    user.completeNewPasswordChallenge(newPassword, reqAttr, {
                        onSuccess: (s) => {
                            resolve(s);
                        },
                        onFailure: (e) => {
                            reject(e);
                        }
                    });
                }
            });
        });

        clearCachedUser(user);

        return user;
    }

    public async initiateAuth(restrictedUser: CognitoUser, type: AuthenticationType): Promise<IAuthResult> {
        const restrictedToken = restrictedUser.getSignInUserSession()?.getAccessToken();
        if (!restrictedToken) {
            throw new Error();
        }

        const user = this.createMultiFactorAuthUser(restrictedToken, type);

        const result = await new Promise<IAuthResult>((resolve, reject) => {
            const authDetails = new AuthenticationDetails({
                Username: user.getUsername(),
            });

            user.initiateAuth(authDetails, {
                customChallenge: (challengeParams) => {
                    const challengeResult: IChallengeAuthResult = {
                        challenge: {
                            user,
                            data: challengeParams,
                        }
                    };
                    resolve(challengeResult);
                },
                onSuccess: (s) => {
                    clearCachedUser(user);
                    reject(new Error(`Shouldn't resolve a session`));
                },
                onFailure: (err) => {
                    reject(err);
                }
            });
        });

        return result;
    }

    public async resolveAuthChallenge(challenge: IMultiFactorAuthChallenge, challengeAnswer?: string): Promise<IAuthResult> {
        const user = challenge.user;
        const challengeId = challenge.data.challengeId;

        const result = await new Promise<IAuthResult>((resolve, reject) => {
            user.sendCustomChallengeAnswer(challengeAnswer || challengeId, {
                customChallenge: (challengeParams: ICognitoChallenge) => {
                    const newChallenge = { ...challengeParams };
                    if (newChallenge.type === 'PAYHAWK_PUSH' && (!newChallenge.createdAt || !newChallenge.expiresAt)) {
                        // TODO: Remove once this is returned from Cognito/Verify API
                        const createdAt = new Date();
                        const expiresAt = new Date(createdAt);
                        expiresAt.setMinutes(expiresAt.getMinutes() + CHALLENGE_DURATION_MINUTES);

                        newChallenge.createdAt = createdAt.toISOString();
                        newChallenge.expiresAt = expiresAt.toISOString();
                    }

                    const challengeResult: IChallengeAuthResult = {
                        challenge: {
                            user,
                            data: newChallenge,
                        }
                    };

                    resolve(challengeResult);
                },
                onSuccess: (s) => {
                    clearCachedUser(user);

                    const sessionResult: ISuccessAuthResult = {
                        session: {
                            accessToken: s.getAccessToken().getJwtToken(),
                            idToken: s.getIdToken().getJwtToken(),
                            refreshToken: s.getRefreshToken().getToken(),
                        }
                    };

                    resolve(sessionResult);
                },
                onFailure: (err) => {
                    reject(err);
                }
            });
        });

        return result;
    }

    public async initPhoneVerification(restrictedUser: CognitoUser): Promise<void> {
        await new Promise<void>((resolve, reject) => {
            restrictedUser.getAttributeVerificationCode('phone_number', {
                onSuccess: () => {
                    resolve();
                },
                onFailure: (err) => {
                    reject(err);
                },
            });
        });
    }

    public async signUp(email: string, onboardingFlow?: string, opportunityId?: string) {
        email = getValidEmail(email);

        if (onboardingFlow) {
            onboardingFlow = onboardingFlow.trim();
            this.validateWorkflow(onboardingFlow);
        }

        if (opportunityId) {
            opportunityId = opportunityId.trim();
            this.validateOpportunityId(opportunityId!);
        }

        const result = await this.apiClient.post<{ userId: string }>('/user/sign-up', { email, onboardingFlow, opportunityId });

        return result.data.userId;
    }

    public async verifySignUp(userId: string, verificationCode: string) {
        userId = getValidString(userId, 'userId');
        verificationCode = getValidString(verificationCode, 'verificationCode');

        const result = await this.apiClient.post<{ email: string, tempPassword: string }>('/user/verify-sign-up', {
            userId,
            code: verificationCode,
        });

        return result.data;
    }

    public async confirmSignUp(userId: string, verificationCode: string): Promise<void> {
        userId = getValidString(userId, 'userId');
        verificationCode = getValidString(verificationCode, 'verificationCode');

        await this.apiClient.post('/user/confirm-sign-up', {
            userId,
            code: verificationCode,
        });
    }

    public async resendVerificationCode(email: string, onboardingFlow?: string) {
        email = getValidEmail(email);

        await this.apiClient.post('/user/resend-sign-up', { email, onboardingFlow });
    }

    public async resetPassword(email: string): Promise<void> {
        email = getValidEmail(email);
        const user = this.createCognitoUser(email, AuthenticationType.Restricted);

        const { status } = await this.getPasswordResetStatus(email);

        if (status !== 'allowed') {
            throw new Error(status);
        }

        await new Promise<void>((resolve, reject) => {
            user.forgotPassword({
                onSuccess: () => {
                    resolve();
                },
                onFailure: (err) => {
                    reject(err);
                }
            });
        });
    }

    public async resetPasswordConfirm(email: string, newPassword: string, verificationCode: string): Promise<void> {
        email = getValidEmail(email);
        newPassword = getValidString(newPassword, 'newPassword');
        verificationCode = getValidString(verificationCode, 'verificationCode');

        const user = this.createCognitoUser(email, AuthenticationType.Restricted);

        await new Promise<void>((resolve, reject) => {
            user.confirmPassword(verificationCode, newPassword, {
                onSuccess: () => {
                    resolve();
                },
                onFailure: (err) => {
                    reject(err);
                }
            });
        });
    }

    public async resendInvitationLink(email: string): Promise<void> {
        const body = { email };
        await this.apiClient.post('/user-resend-invitation-link?email', body);
    }

    public async changePassword(oldPassword: string, newPassword: string, session: IUserSession) {
        const idToken = new CognitoIdToken({ IdToken: session.idToken });
        const email = idToken.decodePayload().email;

        oldPassword = getValidString(oldPassword, 'oldPassword');
        newPassword = getValidString(newPassword, 'newPassword');

        if (!email) {
            throw new Error('The id token doesn\'t contain an email.');
        }

        const user = this.createCognitoUser(email, AuthenticationType.Restricted);
        await new Promise<void>((resolve, reject) => {
            user.setSignInUserSession(new CognitoUserSession({
                IdToken: idToken,
                AccessToken: new CognitoAccessToken({ AccessToken: session.accessToken }),
                RefreshToken: new CognitoRefreshToken({ RefreshToken: session.refreshToken }),
            }));

            user.changePassword(oldPassword, newPassword, (error) => {
                if (error) {
                    reject(error);

                    return;
                }

                resolve();
            });

            clearCachedUser(user);
        });
    }

    public async checkPassword(password: string, session: IUserSession): Promise<boolean> {
        const idToken = new CognitoIdToken({ IdToken: session.idToken });
        const email = idToken.decodePayload().email;

        const validPassword = getValidString(password, 'password');
        if (!email) {
            throw new Error('The id token doesn\'t contain an email.');
        }

        const user = this.createCognitoUser(email, AuthenticationType.Restricted);
        const newSession: CognitoUserSession = await new Promise((resolve, reject) => {
            const authDetails = new AuthenticationDetails({
                Username: email,
                Password: validPassword,
            });
            user.authenticateUser(authDetails, {
                onSuccess: resolve,
                onFailure: reject,
            });
        });

        return newSession.isValid();
    }

    public async findIdentityProvider(email: string): Promise<IFindIdentityProviderResult> {
        const result = await this.payhawkCardsApiClient.get<IFindIdentityProviderResult>(`/identity-providers?search=${encodeURIComponent(email)}`);

        return result.data;
    }

    public async getPasswordResetStatus(email: string): Promise<{ status: string }> {
        const result = await this.apiClient.get(`/user-password-reset-status?email=${encodeURIComponent(email)}`);

        return result.data;
    }

    public async resetSecurityCode(session: IUserSession): Promise<void> {
        await this.payhawkCardsApiClient.post(
            '/security-code/reset',
            {},
            { headers: { 'Authorization': `Bearer ${session.accessToken}` } }
        );
    }

    public async verifySecurityCode(code: string, session: IUserSession, isTemp: boolean = false): Promise<ISecurityCodeResponse> {
        const result = await this.payhawkCardsApiClient.post<ISecurityCodeResponse>(
            '/security-code/verify',
            { code, isTemp },
            { headers: { 'Authorization': `Bearer ${session.accessToken}` } }
        );

        return result.data;
    }

    public async setSecurityCode(newCode: string, oldCode: string, session: IUserSession, isTemp: boolean = false): Promise<void> {
        const result = await this.payhawkCardsApiClient.post(
            '/security-code',
            { code: newCode, oldCode, isTemp },
            { headers: { 'Authorization': `Bearer ${session.accessToken}` } }
        );

        return result.data;
    }

    public async hasSecurityCode(session: IUserSession): Promise<boolean> {
        const result = await this.payhawkCardsApiClient.get<IHasSecurityCodeResponse>(
            '/security-code',
            { headers: { 'Authorization': `Bearer ${session.accessToken}` } }
        );

        return result.data?.hasCode;
    }

    public async getCardDetails(cdeToken: string, code: string, session: IUserSession): Promise<ICardDetailsPayload> {
        const result = await this.payhawkCardsApiClient.post<ICardDetailsPayload>(
            '/card-details',
            { cdeToken, code },
            { headers: { 'Authorization': `Bearer ${session.accessToken}` } }
        );

        return result.data;
    }

    private createMultiFactorAuthUser(restrictedToken: CognitoAccessToken, type: AuthenticationType) {
        const username = restrictedToken.payload.username;
        if (!username) {
            throw Error();
        }

        const user = this.createCognitoUser(username, type);
        user.setAuthenticationFlowType('CUSTOM_AUTH');

        return user;
    }

    private createCognitoUser(username: string, authType: AuthenticationType) {
        const platform = getPlatform();
        const clientId = CONFIG.AUTHENTICATION.clientId[platform][authType];

        return new CognitoUser({
            Username: username,
            Pool: this.getPool(clientId),
        });
    }

    private getPool(clientId: string) {
        return new CognitoUserPool({
            ClientId: clientId,
            UserPoolId: CONFIG.AUTHENTICATION.userPoolId,
            endpoint: CONFIG.AUTHENTICATION.endpoint,
        });
    }

    private validateWorkflow(workflow: string) {
        const ONBOARDING_FLOWS = new Set(['us', 'eu', 'emi', 'lite_world', 'lite_us', 'paynetics']);
        if (!ONBOARDING_FLOWS.has(workflow)) {
            throw new Error('Workflow is not valid');
        }
    }

    private validateOpportunityId(opportunityId: string) {
        const OPPORTUNITY_ID_VALID_SYMBOLS = /^[\w._-]{18}$/;
        const opportunityIdRegex = new RegExp(OPPORTUNITY_ID_VALID_SYMBOLS);
        if (!opportunityIdRegex.test(opportunityId.trim())) {
            throw new Error('Opportunity Id is not valid');
        }
    }
}

function clearCachedUser(user: CognitoUser) {
    // Clear user tokens and data from localStorage
    // This is a private method
    (user as any).clearCachedUser();
}